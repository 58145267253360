import React from 'react';
import utils from "../../../utils/utils";

function CardExternalProduct({ card }) {

    return (
        <div className="card" style={{ borderRadius: 20, width: 300, margin: "auto" }}>
            {
                card?.image?.length > 0 &&
                <img 
                    src={card?.image} 
                    className='w-100' 
                    style={{ 
                        borderTopLeftRadius: 20, 
                        borderTopRightRadius: 20, 
                        height: 300, // Tamaño más grande
                        objectFit: "cover", // Centrar y ajustar la imagen
                        objectPosition: "center" 
                    }} 
                    alt={card?.title} 
                />
            }
            <div className='p-3'>
                {
                    card?.title?.length > 0 &&
                    <div className="w-100 mt-2 mb-2">
                        <h6 style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {card?.title}
                        </h6>
                    </div>
                }
                {
                    card?.cost !== undefined &&
                    <div className="w-100 mt-2 mb-2">
                        <p style={{  }}>
                            {utils?.convertToCOP(card?.cost)}
                        </p>
                    </div>
                }
                {
                    card?.link?.length > 0 &&
                    <div className="w-100 mt-2 mb-2">
                        <a 
                            href={card?.link} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="btn btn-outline-secondary w-100"
                        >
                            Ver en tienda
                        </a>
                    </div>
                }
            </div>
        </div>
    );
}

export default CardExternalProduct;