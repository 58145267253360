import React, { useEffect, useState } from 'react';
import useStore from '../../../store/Store'

import MainLayout from "../../../layouts/MainLayout/MainLayout";
import ScrollableContainer from "../../../layouts/ScrollableContainer/ScrollableContainer";

import CardContentDescription from "../../../components/ui/CardContentDescription/CardContentDescription"
import SpyNavigation from "../../../components/ui/SpyNavigation/SpyNavigation"
import CardJoinGroup from "../../../components/ui/CardJoinGroup/CardJoinGroup"
import HeaderCover from "../../../components/ui/HeaderCover/HeaderCover"

import coverNewBeginning from "../../../assets/cover-new-beginnning.png";
import coverEvangelizerAndMissionary from "../../../assets/cover-evangelizer-and-missionary.png";
import coverBuildYourFaith from "../../../assets/cover-build-your-faith.png";
import coverMigrant from "../../../assets/cover-migrant.png";

import CardProduct from "../../../components/ui/CardProduct/CardProduct"
import InternalBookOfferContainer from "../../../components/InternalBookOfferContainer/InternalBookOfferContainer";
import ExternalBookOfferContainer from "../../../components/ExternalBookOfferContainer/ExternalBookOfferContainer";

import WhiteSectionOverlappedBySimpleCards from "../../../components/WhiteSectionOverlappedBySimpleCards/WhiteSectionOverlappedBySimpleCards"
import BlueSectionOverlappedBySimpleCards from "../../../components/BlueSectionOverlappedBySimpleCards/BlueSectionOverlappedBySimpleCards";
import PreviewCardResourcesStore from "../../../components/PreviewCardResourcesStore/PreviewCardResourcesStore"
import CoursesAndTrainingSection from "../../../components/CoursesAndTrainingSection/CoursesAndTrainingSection"

const HEADER_CONFIG = {
    type: "solid", // solid, image,
    resource: "var(--bs-secondary)", // bg-secondary || cover-home
    icon: "new-beginning-white",
    title: "Cada gran historia de fe comienza con un\n primer paso. ¿Estás listo para empezar el tuyo",
    showBorder: true,
    description: "",
    button: {
        show: true,
        text: "Empieza tu discipulado hoy",
        type: "btn",
        external: false,
        path: "/training"
    }
}

function NewBeginning() {
    const { config, courses, webinars, forums, resources } = useStore();

    const preProcessSpyNavigations = () => {
        return [
            { id: 'section1', title: 'Primeros Pasos' },
            { id: 'section2', title: 'Cursos básicos' },
            { id: 'section3', title: 'Peticiones de oración' },
            { id: 'section4', title: 'Recomendaciones de Biblias y devocionales' }
        ]
    }

    const preProcessBlueSection = () => {
        return {
            mainContext: {
                title: "Primeros pasos",
                subtitle: "Comienza tu Camino de Fe",
                description: "Bienvenido al inicio de tu viaje espiritual. Aquí te ofrecemos recursos prácticos para fortalecer tu fe y aprender a conectar con Dios de manera más profunda. Estos primeros pasos te guiarán en el proceso de aprender a orar y leer la Biblia, herramientas esenciales para tu crecimiento en la fe.",
                textButton: "",
                link: ""
            },
            simpleCards: webinars?.data?.map(item => {
                return {
                    title: item?.title,
                    date: item?.date,
                    description: item?.description,
                    textButton: "Quiero saber más",
                    link: item?.link
                }
            })
        }
    }

    const preProcessWhiteSection = () => {
        return {
            mainContext: {
                title: "Estos cursos están diseñados para guiarte en los primeros pasos de tu camino espiritual.",
                description: "Aprende lo esencial sobre la fe cristiana, la oración, y la interpretación de la Biblia para profundizar tu relación con Dios.",
                textButton: "¡Comienza tu aprendizaje hoy!",
                link: "/training"
            },
            simpleCards: courses?.data?.map(item => {
                return {
                    title: item?.title,
                    tag: item?.tag,
                    img: item?.image,
                    description: item?.description,
                    textButton: "Inscribirme",
                    link: item?.link
                }
            })
        }
    }

    const preProcessForum = (forum) => {
        return {
            title: "Oración por Paz y Fortaleza",
            createdBy: "12/03/2025",
            status: "Activo",
            description: "Este foro está dedicado a aquellos que buscan paz y fortaleza en momentos difíciles. Comparte tus oraciones, experiencias y súplicas para que juntos podamos elevar nuestras voces al Señor por el consuelo y la paz en tiempos de tribulación.",
            btnJoin: {
                show: true,
                text: "Unírme al foro",
                path: forum?.link
            },
            comments: [],
            members: [],
            ...forum
        }
    }

    return (
        <MainLayout>
            <HeaderCover data={HEADER_CONFIG} />

            <div className="bg-secondary" style={{ height: 200 }}></div>

            <div className="container mb-5">
                <img className="w-100" src={coverNewBeginning} style={{ marginTop: -250 }} />
            </div>

            {/* Spy Navigation */}
            <SpyNavigation navigations={preProcessSpyNavigations()} />

            {/* Content Sections */}

            <section id="section1" className="container">
                <BlueSectionOverlappedBySimpleCards mainContext={preProcessBlueSection()?.mainContext} simpleCards={preProcessBlueSection()?.simpleCards} />
            </section>

            <section id="section2" className="container">
                <WhiteSectionOverlappedBySimpleCards mainContext={preProcessWhiteSection()?.mainContext} simpleCards={preProcessWhiteSection()?.simpleCards} />
            </section>

            <section id="section3" className="container">
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center pb-5">
                            <CardContentDescription cards={[
                                {
                                    type: "white",
                                    title: "Peticiones de Oración: Comparte tus Necesidades y Ora por Otros",
                                    showBorder: true,
                                    rightContent: false,
                                    description: "",
                                    button: {
                                        show: false,
                                        text: "¡Comienza tu aprendizaje hoy!",
                                        type: "btn",
                                        external: false,
                                        path: "/any-where"
                                    }
                                }
                            ]} />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <CardJoinGroup card={preProcessForum(forums?.data[Math.floor(Math.random() * forums.data.length)])} />
                        </div>
                    </div>
                </div>
            </section>

            <section id="section4" className="container-fluid bg-info">
                <PreviewCardResourcesStore data={{
                    title: "Recomendaciones de Biblias y Devocionales para tu Crecimiento Espiritual",
                    showBorder: true,
                    description: "Accede a recursos gratuitos y descubre libros físicos que enriquecerán tu fe.",
                    sections: []
                }} />
                <InternalBookOfferContainer resources={resources?.data?.filter(item => item.cost == 0)} />
                <ExternalBookOfferContainer resources={resources?.data?.filter(item => item.cost > 0)} />
            </section>


        </MainLayout>
    );
}

export default NewBeginning;