import React from 'react';
import utils from "../../../utils/utils";

import { Link } from 'wouter'; // Importa Link y useLocation de wouter

import iconCalendar from "../../../assets/calendar_today.png"

function CardProduct({ card }) {
    return (
        <div className={card?.bg} style={{ borderRadius: 20, width: 300, margin: "auto" }}>
            {
                card?.img?.length > 0 &&
                <img src={card?.img} className='w-100' style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20, maxHeight: 250 }} />
            }
            <div className='p-3'>
                {
                    card?.tag?.length > 0 &&
                    <div className="w-100 d-flex">
                        <div className='d-flex me-auto'>
                            <p className='border border-primary border-2 p-2 rounded'>{card?.tag}</p>
                        </div>
                    </div>
                }
                {
                    card?.title?.length > 0 &&
                    <div className="w-100 mt-2 mb-2">
                        <h6 style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {card?.title?.split('\n')?.map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    {index < card.title.split('\n').length - 1 && <br />}
                                </React.Fragment>
                            ))}
                        </h6>
                    </div>
                }
                {
                    card?.subtitle?.length > 0 &&
                    <div className="w-100 mt-2 mb-2">
                        <h7 style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {card?.subtitle?.split('\n')?.map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    {index < card.subtitle.split('\n').length - 1 && <br />}
                                </React.Fragment>
                            ))}
                        </h7>
                    </div>
                }
                {
                    card?.date?.length > 0 &&
                    <div className="w-100 d-flex mt-1 mb-1">
                        <ion-icon name="calendar-clear-outline" style={{ fontSize: 24, marginTop: "auto", marginBottom: "auto" }}></ion-icon>
                        <p className='ms-2' style={{ marginTop: "auto", marginBottom: "auto" }}>
                            {utils?.formatDateTime(card?.date)?.split('\n')?.map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    {index < utils?.formatDateTime(card?.date).split('\n').length - 1 && <br />}
                                </React.Fragment>
                            ))}
                        </p>
                    </div>
                }
                {
                    card?.description?.length > 0 &&
                    <div className="w-100 d-flex mt-1 mb-1">
                        <p style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{card?.description}</p>
                    </div>
                }
                {
                    card?.buttonOne?.show && (
                        utils?.isExternalLink(card?.buttonOne?.path) ? (
                            <a
                                href={card?.buttonOne?.path}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={card?.buttonOne?.type}
                            >
                                <p>{card?.buttonOne?.text}</p>
                            </a>
                        ) : (
                            <Link className={card?.buttonOne?.type} href={card?.buttonOne?.path}>
                                {card?.buttonOne?.text}
                            </Link>
                        )
                    )
                }
                {
                    card?.buttonTwo?.show && (
                        utils?.isExternalLink(card?.buttonTwo?.path) ? (
                            <a
                                href={card?.buttonTwo?.path}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={card?.buttonTwo?.type}
                            >
                                <p>{card?.buttonTwo?.text}</p>
                            </a>
                        ) : (
                            <Link className={card?.buttonTwo?.type} href={card?.buttonTwo?.path}>
                                {card?.buttonTwo?.text}
                            </Link>
                        )
                    )
                }
            </div>
        </div>
    );
}

export default CardProduct;