import React from 'react';
import Navbar from '../../components/ui/Navbar/Navbar';
import Footer from '../../components/ui/Footer/Footer';

function MainLayout({ children }) {
  return (
    <div>
      <Navbar />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default MainLayout;