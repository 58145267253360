const normalizeText = (text) => {
    return text
        .normalize("NFD") // Normaliza el texto a su forma descompuesta (separando caracteres y tildes)
        .replace(/[\u0300-\u036f]/g, "") // Elimina las tildes
        .replace(/[.,]/g, ""); // Elimina comas y puntos
};

const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    // Months in Spanish
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    // Get date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Get time components
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, set to 12

    return "15 de Noviembre de 2023 \n9:00 AM"

    return `${day} de ${month} de ${year}\n${hours}:${minutes} ${ampm}`;
};

// Función para mezclar un array usando el algoritmo de Fisher-Yates
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

// Convertir el costo de USD a COP (asumiendo una tasa de cambio de 1 USD = 4000 COP)
const convertToCOP = (usd) => {
    const exchangeRate = 4000; // Tasa de cambio aproximada
    return (usd * exchangeRate).toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
};

const isExternalLink = (path) => {
    return path?.startsWith('http://') || path?.startsWith('https://');
};

export default { normalizeText, formatDateTime, shuffleArray, convertToCOP, isExternalLink }