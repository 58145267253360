import React, { useEffect, useState } from 'react';
import useStore from '../../store/Store';

import utils from "../../utils/utils";

import MainLayout from "../../layouts/MainLayout/MainLayout";
import CardContentDescription from "../../components/ui/CardContentDescription/CardContentDescription";
import CardProductExternal from "../../components/ui/CardProduct/CardProductExternal"
import CardProduct from "../../components/ui/CardProduct/CardProduct";
import HeaderCover from "../../components/ui/HeaderCover/HeaderCover"

import CardJoinGroup from "../../components/ui/CardJoinGroup/CardJoinGroup"

function Community() {
  const { config, forums } = useStore();

  const HEADER_CONFIG = {
    type: "image", // solid, image,
    resource: "cover-home", // bg-secondary || cover-home
    icon: "",
    title: "La fe crece cuando la compartimos. \nÚnete a una comunidad que camina contigo...",
    showBorder: true,
    description: "Conéctate con creyentes de todo el mundo y comparte experiencias que transforman vidas.",
    button: {
      show: true,
      text: "Participa en nuestros grupos",
      type: "btn",
      external: false,
      path: config?.data?.linkCommunity || "/"
    }
  }

  const LIMIT = 3;
  const [page, setPage] = useState(1);

  const totalPages = Math.ceil((forums?.data?.length || 0) / LIMIT);
  const hasNextPage = page < totalPages;
  const hasPrevPage = page > 1;

  const handleNextPage = () => hasNextPage && setPage(prev => prev + 1);
  const handlePrevPage = () => hasPrevPage && setPage(prev => prev - 1);

  const paginatedForums = forums?.data?.slice((page - 1) * LIMIT, page * LIMIT);

  const preProcessForum = (forum) => {
    return {
      title: "Oración por Paz y Fortaleza",
      createdBy: "12/03/2025",
      status: "Activo",
      description: "Este foro está dedicado a aquellos que buscan paz y fortaleza en momentos difíciles. Comparte tus oraciones, experiencias y súplicas para que juntos podamos elevar nuestras voces al Señor por el consuelo y la paz en tiempos de tribulación.",
      btnJoin: {
        show: true,
        text: "Unírme al foro",
        path: forum?.link
      },
      comments: [],
      members: [],
      ...forum
    }
  }

  useEffect(() => setPage(1), [forums]);

  return (
    <MainLayout>

      <HeaderCover data={HEADER_CONFIG} />

      <div className="container mt-5 mb-5">
        <div className="row mt-4">
          <div className="col-12 col-lg-3">
            <div className="card card-body bg-light2">
              <div className="w-100 d-flex">
                <button className="btn w-100 d-flex border" style={{ backgroundColor: "#c7d1d9" }}>
                  <div className="mt-1 mb-auto me-2 text-white bg-secondary p-3 pt-1 pb-1 rounded">
                    <ion-icon name="chatbubbles-sharp"></ion-icon>
                  </div>
                  <p className="fw-bold mt-auto mb-auto m-0 text-secondary">Foros y grupos</p>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-9">
            {
              paginatedForums?.map((forum, index) => {
                return (
                  <div className="mb-5">
                    <CardJoinGroup card={preProcessForum(forum)} />
                  </div>
                )
              })
            }
          </div>
        </div>
        {totalPages > 1 && (
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center gap-3">
              <button className="btn btn-outline-primary" onClick={handlePrevPage} disabled={!hasPrevPage}>
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <div className="d-flex align-items-center">
                <span className="fw-bold">Página {page} de {totalPages}</span>
              </div>
              <button className="btn btn-outline-primary" onClick={handleNextPage} disabled={!hasNextPage}>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default Community;
