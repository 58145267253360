import React, { useEffect, useState } from 'react';
import useStore from '../../store/Store';

import utils from "../../utils/utils";

import MainLayout from "../../layouts/MainLayout/MainLayout";
import CardContentDescription from "../../components/ui/CardContentDescription/CardContentDescription";
import CardProductExternal from "../../components/ui/CardProduct/CardProductExternal"
import CardProduct from "../../components/ui/CardProduct/CardProduct";

import CoursesAndTrainingSection from '../CoursesAndTrainingSection/CoursesAndTrainingSection';

function Filters({ contentTypes, categories, selectedTypes, selectedcategories, handleTypeChange, handleCategoryChange, handleChangeFilter }) {
    return (
        <div className="card p-3">
            <div className="mb-4">
                <h5 className="mb-3">Buscar</h5>
                <div className="col-12 col-md-12 col-xxl-6 d-flex mt-2 form-floating mb-3 ps-1 pe-1 w-100">
                    <input onChange={handleChangeFilter} type="text" className="form-control" id="floatingInput" placeholder="Buscar por título o autor..." />
                    <label htmlFor="floatingInput">Buscar por título o autor...</label>
                </div>
            </div>
            <div className="mb-4">
                <h5 className="mb-3">Tipo de contenido</h5>
                {contentTypes?.map(type => (
                    <div key={type.id} className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={type.id}
                            checked={selectedTypes.includes(type.id)}
                            onChange={() => handleTypeChange(type.id)}
                        />
                        <label className="form-check-label" htmlFor={type.id}>{type.label}</label>
                    </div>
                ))}
            </div>
            <div className="mb-4">
                <h5 className="mb-3">Categorías</h5>
                {categories?.map(category => (
                    <div key={category.id} className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={category.id}
                            checked={selectedcategories.includes(category.id)}
                            onChange={() => handleCategoryChange(category.id)}
                        />
                        <label className="form-check-label" htmlFor={category.id}>{category.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

// contentTypes = [ { id, label } ] -> type
// categories = [ { id, label } ] -> tag
// resources = [ { _id, type, title, tag, image, link, cost, creationDate } ]
function StoreLibraryWithAdvanceFilters({ contentTypes, categories, resources: resourcesAll }) {
    const resources = resourcesAll?.filter(resource => {
        return contentTypes?.map(ct => ct.id)?.includes(resource.type) && categories?.map(ct => ct.id)?.includes(resource.tag);
    });

    const [searchText, setSearchText] = useState("");
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedcategories, setSelectedcategories] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleTypeChange = (typeId) => {
        const updatedTypes = selectedTypes.includes(typeId)
            ? selectedTypes.filter(id => id !== typeId)
            : [...selectedTypes, typeId];
        setSelectedTypes(updatedTypes);
    };

    const handleCategoryChange = (categoryId) => {
        const updatedcategories = selectedcategories.includes(categoryId)
            ? selectedcategories.filter(id => id !== categoryId)
            : [...selectedcategories, categoryId];
        setSelectedcategories(updatedcategories);
    };

    const handleChangeFilter = (e) => setSearchText(e.target.value);

    const filteredResources = resources?.filter(resource => {
        const matchesSearch = searchText === "" || resource.title?.toLowerCase().includes(searchText.toLowerCase());
        const matchesType = selectedTypes.length === 0 || selectedTypes.includes(resource.type);
        const matchesCategory = selectedcategories.length === 0 || selectedcategories.includes(resource.tag);
        return matchesSearch && matchesType && matchesCategory;
    });

    const preProcessCourses = () => filteredResources?.map(item => ({
        bg: "border bg-white",
        img: item?.image,
        title: item?.title,
        tag: item?.tag,
        date: item?.creationDate,
        description: "",
        buttonOne: {
            show: true,
            text: "Descargar",
            type: "w-100 mt-1 mb-1 btn btn-outline-secondary",
            path: item?.link
        },
        buttonTwo: {
            show: true,
            text: "Leer en línea",
            type: "w-100 mt-1 mb-1 btn btn-secondary",
            path: item?.link
        },
        type: item?.type,
        cost: item?.cost
    }));

    return (
        <div className="container mt-5 mb-5">
            <div className="row mt-4">
                <div className="d-none d-lg-block col-lg-3">
                    <Filters
                        contentTypes={contentTypes}
                        categories={categories}
                        selectedTypes={selectedTypes}
                        selectedcategories={selectedcategories}
                        handleTypeChange={handleTypeChange}
                        handleCategoryChange={handleCategoryChange}
                        handleChangeFilter={handleChangeFilter}
                    />
                </div>
                <div className="d-lg-none sticky-top bg-white shadow-sm">
                    <div className="container py-2">
                        <button className="btn btn-outline-primary w-100" onClick={() => setShowModal(true)}>
                            <ion-icon name="filter-outline"></ion-icon> Filtros
                        </button>
                    </div>
                    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1">
                        <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Filtros</h5>
                                    <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                                </div>
                                <div className="modal-body">
                                    <Filters
                                        contentTypes={contentTypes}
                                        categories={categories}
                                        selectedTypes={selectedTypes}
                                        selectedcategories={selectedcategories}
                                        handleTypeChange={handleTypeChange}
                                        handleCategoryChange={handleCategoryChange}
                                        handleChangeFilter={handleChangeFilter}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => setShowModal(false)}>Aplicar filtros</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showModal && <div className="modal-backdrop fade show" onClick={() => setShowModal(false)}></div>}
                </div>
                <div className="col-12 col-lg-9">
                    <CoursesAndTrainingSection courses={preProcessCourses()} />
                </div>
            </div>
        </div>
    );
}

export default StoreLibraryWithAdvanceFilters;