import React from 'react';

import footerHome from "../../../../assets/footer-home.png"

const Footer = () => {
  return (
    <footer className="container-fluid bg-primary text-light position-relative pt-2 mt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5 mb-5 pb-4">
            <h4 className="mb-4">
              Descubre nuestra tienda en línea donde encontrarás productos únicos para fortalecer tu fe y llevarla contigo a cada paso
            </h4>
            <button className="btn btn-secondary btn-lg"
              onClick={() => window.open('https://tiendadelabiblia.com/', '_blank')}>
              Fe en Linea Tienda Virtual
            </button>
          </div>
          <div className="col-md-7 position-relative d-flex justify-content-end">
            <img
              src={footerHome}
              alt="Store Featured"
              className="img-fluid footer-image"
              style={{
                marginTop: '-50px',
                maxWidth: '100%',
                height: 'auto'
              }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
