import MainLayout from "../../layouts/MainLayout/MainLayout";

function Donations() {
    return (
        <MainLayout>
            <p>Donations</p>
        </MainLayout>
    );
}

export default Donations;
