import React from 'react';
import { Link } from 'wouter';

import utils from '../../../utils/utils';

import borderPrimary from "../../../assets/border-primary.png"
import borderWhite from "../../../assets/border-white.png"

import coverHome from "../../../assets/cover-home.png"
import coverNewBeginning from "../../../assets/cover-new-beginnning.png"

import migrant from "../../../assets/migrant.png"
import migrantWhite from "../../../assets/migrant-white.png"
import newBeginning from "../../../assets/new-beginning.png"
import newBeginningWhite from "../../../assets/new-beginning-white.png"
import evangelizerAndMissionary from "../../../assets/evangelizer-and-missionary.png"
import evangelizerAndMissionaryWhite from "../../../assets/evangelizer-and-missionary-white.png"
import buildYourFaith from "../../../assets/build-your-faith.png"
import buildYourFaithWhite from "../../../assets/build-your-faith-white.png"

const DICTIONARY_IMAGES = {
  "cover-home": coverHome,
  "cover-new-beginnning": coverNewBeginning,

  "migrant": migrant,
  "migrant-white": migrantWhite,
  "new-beginning": newBeginning,
  "new-beginning-white": newBeginningWhite,
  "evangelizer-and-missionary": evangelizerAndMissionary,
  "evangelizer-and-missionary-white": evangelizerAndMissionaryWhite,
  "build-your-faith": buildYourFaith,
  "build-your-faith-white": buildYourFaithWhite,
}

function HeaderCover({ data }) {
  const THEME = {
    "solid": {
      "card": {
        height: 500,
        backgroundColor: data?.resource,
        alignItem: "center",
        alignContent: "center"
      },
      "title": "mb-0 text-white f-playfair text-center",
      "img": borderWhite,
      "description": "text-white mb-2 mb-2 mt-2",
      "button": {
        "btn": "btn bg-primary text-white fw-bold mt-3",
        "link": "btn bg-white text-white border-bottom ps-0 fw-bold pt-3"
      }
    },
    "image": {
      "card": {
        height: 500,
        backgroundImage: `url(${DICTIONARY_IMAGES[data?.resource]})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        alignItem: "center",
        alignContent: "center"
      },
      "title": "mb-0 text-white f-playfair text-center",
      "img": borderWhite,
      "description": "text-white mb-2 mb-2 mt-2",
      "button": {
        "btn": "btn bg-primary text-white fw-bold mt-3",
        "link": "btn bg-white text-white border-bottom ps-0 fw-bold pt-3"
      }
    }
  }

  const getTheme = (key, subkey) => {
    const themePivot = THEME[data?.type]
    if (key == "button") {
      const button = themePivot[key]
      return button[subkey]
    }
    return themePivot[key];
  }

  const handleClick = () => {
    // neko note
    const button = data?.button
    console.log(button)
  }

  return (
    <div className='container-fluid d-flex flex-column justify-content-center align-items-center' style={getTheme("card")}>
      {
        data?.icon?.length > 0 &&
        <img src={DICTIONARY_IMAGES[data?.icon]} style={{ width: 55, marginTop: 20, marginBottom: 20 }} />
      }
      {
        data?.title?.length > 0 &&
        <h1 className={getTheme("title")} style={{ maxWidth: 1000 }}>
          {data?.title?.split('\n')?.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < data.title.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))}
        </h1>
      }
      {
        data?.showBorder &&
        <img src={getTheme("img")} style={{ width: 55, marginTop: 15, marginBottom: 20 }} />
      }
      {
        data?.description?.length > 0 &&
        <p className={getTheme("description")} style={{ maxWidth: 1000 }}>{data?.description}</p>
      }
      {
        data?.button?.show && <div>
          {
            data?.button?.type == "btn" ? (
              // Si el tipo de botón es "btn"
              utils?.isExternalLink(data?.button?.path) ? (
                  // Enlace externo: abre en una nueva pestaña
                  <a
                      href={data?.button?.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={getTheme("button", data?.button?.type)}
                  >
                      <p>{data?.button?.text}</p>
                  </a>
              ) : (
                  // Ruta relativa: usa wouter para la navegación
                  <Link to={data?.button?.path}>
                      <button
                          onClick={handleClick}
                          className={getTheme("button", data?.button?.type)}
                      >
                          <p>{data?.button?.text}</p>
                      </button>
                  </Link>
              )
          ) : (
              // Si el tipo de botón no es "btn"
              utils?.isExternalLink(data?.button?.path) ? (
                  // Enlace externo: abre en una nueva pestaña
                  <a
                      href={data?.button?.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={getTheme("button", data?.button?.type)}
                  >
                      <p>{data?.button?.text}</p>
                  </a>
              ) : (
                  // Ruta relativa: usa wouter para la navegación
                  <Link to={data?.button?.path}>
                      <button
                          onClick={handleClick}
                          className={getTheme("button", data?.button?.type)}
                      >
                          <p>{data?.button?.text}</p>
                      </button>
                  </Link>
              )
          )
          }
        </div>
      }
    </div>
  );
}

export default HeaderCover;