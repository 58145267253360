import React, { useRef } from 'react';

import quotas from "../../../../assets/quotas.png"

function Testimonial({testimonials}) {
    const scrollRef = useRef(null);

    const scroll = (direction) => {
        const container = scrollRef.current;
        const scrollAmount = 300; // Adjust this value to control scroll distance

        if (container) {
            container.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="container py-5 position-relative">
                    <h2 className="text-center mb-5 f-playfair">Testimonios de usuarios</h2>

                    {/* Scroll Container */}
                    <div
                        ref={scrollRef}
                        className="d-flex overflow-hidden"
                        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
                    >
                        <div className="d-flex gap-4">
                            {testimonials?.map((testimonial, index) => (
                                <div
                                    key={index}
                                    className="card bg-light2 border-0"
                                    style={{
                                        borderRadius: 22,
                                        minWidth: '300px',
                                        marginTop: index % 2 === 0 ? '1rem' : '3rem',
                                        marginLeft: 10,
                                        marginBottom: index % 2 !== 0 ? '1rem' : '3rem',
                                    }}
                                >
                                    <img src={quotas} style={{ width: 88, height: 55, marginTop: -10, marginLeft: -10 }} />
                                    <div className="card-body p-4">
                                        <p className="card-text text-secondary mb-4">
                                            "{testimonial.quote}"
                                        </p>
                                        <p className="card-text text-secondary fw-bold mb-0">
                                            {testimonial.author}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-100 d-flex mt-3" style={{ padding: 32, paddingTop: 0 }}>
                        <div className="flex gap-4 m-auto">
                            <button
                                onClick={() => scroll('left')}
                                className="btn bg-light2 me-2"
                            >
                                <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button
                                onClick={() => scroll('right')}
                                className="btn bg-light2"
                            >
                                <ion-icon name="chevron-forward-outline"></ion-icon>
                            </button>
                        </div>
                    </div>


                    {/* Custom CSS to hide scrollbar */}
                    <style>
                        {`
                    div::-webkit-scrollbar {
                        display: none;
                    }
                `}
                    </style>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;