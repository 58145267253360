import React from 'react';

import borderPrimary from "../../assets/border-primary.png"
import borderwhite from "../../assets/border-white.png"

import CardProduct from "../../components/ui/CardProduct/CardProduct"

function PreviewCardResourcesStore({ data, forceTextWhite = false }) {
    return (
        <div className={forceTextWhite ? 'bg-transparent pt-5 pb-0 m-auto container text-white' : 'bg-info pt-5 pb-0 m-auto container'}>
            {
                data?.title?.length > 0 &&
                <h2>{data?.title}</h2>
            }
            {
                data?.showBorder &&
                <img src={borderPrimary} style={{ width: 55, marginTop: 15, marginBottom: 20 }} />
            }
            {
                data?.description?.length > 0 &&
                <h4 className='mt-2'>{data?.description}</h4>
            }
            {
                data?.sections?.map(item => {
                    return (
                        <>
                            <div className="w-100 d-flex mt-5 border-secondary pb-3 mt-3 border-bottom">
                                <h4 className='mt-auto mb-auto'>{item?.title}</h4>
                                {
                                    item?.button?.show && (
                                        <button className='btn btn-secondary ms-auto'>
                                            {item?.button?.text}
                                        </button>
                                    )
                                }
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    {
                                        item?.cards?.map(card => {
                                            return (
                                                <div className="col-12 col-md-6 col-xl-3 pt-5">
                                                    <CardProduct card={card} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    )
                })
            }
        </div>
    );
}

export default PreviewCardResourcesStore;