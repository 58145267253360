import React, { useState } from 'react';
import { Link } from 'wouter';

import migrant from "../../../assets/migrant.png"
import migrantWhite from "../../../assets/migrant-white.png"
import newBeginning from "../../../assets/new-beginning.png"
import newBeginningWhite from "../../../assets/new-beginning-white.png"
import evangelizerAndMissionary from "../../../assets/evangelizer-and-missionary.png"
import evangelizerAndMissionaryWhite from "../../../assets/evangelizer-and-missionary-white.png"
import buildYourFaith from "../../../assets/build-your-faith.png"
import buildYourFaithWhite from "../../../assets/build-your-faith-white.png"

const BUTTONS = [
    {
        text: "Nuevo Comienzo",
        path: "/new-beginning",
        theme: "bg-light2 text-secondary",
        img: newBeginning,
        themeHover: "bg-secondary text-white",
        imgHover: newBeginningWhite
    },
    {
        text: "Construye tu Fe",
        path: "/build-your-faith",
        theme: "bg-light2 text-secondary",
        img: buildYourFaith,
        themeHover: "bg-secondary text-white",
        imgHover: buildYourFaithWhite
    },
    {
        text: "Evangelizador",
        path: "/evangelizer-and-missionary",
        theme: "bg-light2 text-secondary",
        img: evangelizerAndMissionary,
        themeHover: "bg-secondary text-white",
        imgHover: evangelizerAndMissionaryWhite
    },
    {
        text: "Migrante",
        path: "/migrant",
        theme: "bg-light2 text-secondary",
        img: migrant,
        themeHover: "bg-secondary text-white",
        imgHover: migrantWhite
    },
]

function Blank() {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <div className="container">
            <div className="row">
                <div className="container">

                    <div className='container-fluid' style={{ marginTop: -50 }}>
                        <div className="row bg-light2 p-3" style={{ borderRadius: 20 }}>
                            {
                                BUTTONS?.map((button, index) => {
                                    const isHovered = hoveredIndex === index;
                                    return (
                                        <Link href={button?.path}>
                                            <div
                                                key={index}
                                                style={{ cursor: "pointer", borderRadius: 10 }}
                                                className={`col-12 col-sm-6 col-lg-3 p-3 ${isHovered ? button.themeHover : button.theme}`}
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <div className={`w-100 d-flex`}>
                                                    <div style={{ width: "40%" }} className='d-flex'>
                                                        <img
                                                            src={isHovered ? button.imgHover : button.img}
                                                            className="logo-button m-auto"
                                                            alt={button.text}
                                                        />
                                                    </div>
                                                    <div style={{ width: "60%" }} className='d-flex'>
                                                        <p className="m-auto">
                                                            {button.text}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Blank;