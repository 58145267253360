import React, { useEffect, useState } from 'react';
import useStore from '../../store/Store';

import utils from "../../utils/utils";

import MainLayout from "../../layouts/MainLayout/MainLayout";
import CardContentDescription from "../../components/ui/CardContentDescription/CardContentDescription";
import CardProductExternal from "../../components/ui/CardProduct/CardProductExternal"
import CardProduct from "../../components/ui/CardProduct/CardProduct";

function CoursesAndTrainingSection({ courses }) {
    const LIMIT = 3;
    const [page, setPage] = useState(1);

    const totalPages = Math.ceil((courses?.length || 0) / LIMIT);
    const hasNextPage = page < totalPages;
    const hasPrevPage = page > 1;

    const handleNextPage = () => hasNextPage && setPage(prev => prev + 1);
    const handlePrevPage = () => hasPrevPage && setPage(prev => prev - 1);

    const paginatedCourses = courses?.slice((page - 1) * LIMIT, page * LIMIT);

    useEffect(() => setPage(1), [courses]);

    return (
        <div id="section1" className='container-fluid mt-5'>
            <div className="row">
                {paginatedCourses?.map(card => (
                    <div key={card.id} className="col-12 col-md-6 col-lg-4 mb-2">
                        <CardProduct card={card} />
                    </div>
                ))}
            </div>
            {totalPages > 1 && (
                <div className="row mt-4">
                    <div className="col-12 d-flex justify-content-center gap-3">
                        <button className="btn btn-outline-primary" onClick={handlePrevPage} disabled={!hasPrevPage}>
                            <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <div className="d-flex align-items-center">
                            <span className="fw-bold">Página {page} de {totalPages}</span>
                        </div>
                        <button className="btn btn-outline-primary" onClick={handleNextPage} disabled={!hasNextPage}>
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CoursesAndTrainingSection;