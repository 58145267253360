import { useState, useEffect } from 'react'
import { Router, Route } from "wouter";

import useStore from './store/Store.js'

import LoadingOverlay from "./components/ui/LoadingOverlay/LoadingOverlay.jsx";

import Home from "./views/Start/Home/Home.jsx";
import Migrant from "./views/Start/Migrant/Migrant.jsx";
import NewBeginning from "./views/Start/NewBeginning/NewBeginning.jsx";
import BuildYourFaith from "./views/Start/BuildYourFaith/BuildYourFaith.jsx";
import EvangelizerAndMissionary from "./views/Start/EvangelizerAndMissionary/EvangelizerAndMissionary.jsx";

import Help from './views/Help/Help.jsx'
import Library from './views/Library/Library.jsx'
import Training from './views/Training/Training.jsx'
import Donations from './views/Donations/Donations.jsx'
import Community from './views/Community/Community.jsx'
import Evangelism from './views/Evangelism/Evangelism.jsx'

import Test from "./components/Test.jsx"
import Lab from "./components/Lab.jsx"

function App() {
  const { isAuth, fetchConfig, fetchUserProfile, fetchCourses, fetchWebinars, fetchTestimonials, fetchResources, fetchForums } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const init = async () => {
    setIsLoading(true);
    const listProcess = [
      fetchConfig(),
      fetchCourses(0, 500),
      fetchWebinars(0, 500),
      fetchTestimonials(0, 500),
      fetchResources(0, 500),
      fetchForums(0, 500),
    ]
    await Promise.all(listProcess);
    setIsLoading(false);
  }

  useEffect(() => {
    if (isAuth) {
      fetchUserProfile()
    }
  }, [isAuth])

  useEffect(() => {
    init()
  }, [])

  return (
    <div className="App">{
      /* Mostrar el LoadingOverlay si isLoading es true */}
      {isLoading && <LoadingOverlay />}

      <Router >
        <Route path="/" component={Home} />
        <Route path="/migrant" component={Migrant} />
        <Route path="/new-beginning" component={NewBeginning} />
        <Route path="/build-your-faith" component={BuildYourFaith} />
        <Route path="/evangelizer-and-missionary" component={EvangelizerAndMissionary} />

        <Route path="/help" component={Help} />
        <Route path="/library" component={Library} />
        <Route path="/training" component={Training} />
        <Route path="/donations" component={Donations} />
        <Route path="/community" component={Community} />
        <Route path="/evangelism" component={Evangelism} />
        {
          isAuth &&
          <Route path="/login" component={Lab} />
        }
      </Router>
    </div>
  );
}

export default App;
