import React, { useEffect, useState } from 'react';

// [ { id: 'section1', title: 'Cursos y formación' }, { id: 'section2', title: 'Calendario de Webinars' } ]
function SpyNavigation({ navigations }) {
    const [activeSection, setActiveSection] = useState(navigations[0].id);

    useEffect(() => {
        const handleScroll = () => {
            const sections = navigations?.map(section => {
                const element = document.getElementById(section.id);
                if (element) {
                    return {
                        id: section.id,
                        offset: Math.abs(element.getBoundingClientRect().top)
                    };
                }
                return null;
            }).filter(Boolean);

            const closest = sections?.reduce((prev, curr) => {
                return prev.offset < curr.offset ? prev : curr;
            });

            setActiveSection(closest?.id);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="sticky-top bg-white">
            <div className="container">
                <div className="d-flex justify-content-center gap-5 py-3">
                    {navigations.map((section) => (
                        <a
                            key={section.id}
                            href={`#${section.id}`}
                            className={`text-decoration-none text-secondary ${activeSection === section.id ? 'fw-bold border-bottom border-2 border-secondary' : ''
                                }`}
                            onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(section.id).scrollIntoView({
                                    behavior: 'smooth'
                                });
                            }}
                        >
                            <div className="d-none d-lg-block">
                                <h4>{section.title}</h4>
                            </div>
                            <div className="d-block d-lg-none">
                                <p className="mb-0">{section.title}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SpyNavigation;