import { Link } from 'wouter';

import logo from '../../../assets/logo.png'

function Footer() {
    return (
        <div className="container-fluid bg-secondary">
            <div className="row pt-4">
                <div className="mb-2 col-12 col-sm-6 col-lg-4 p-2">
                    <div className="w-100 d-flex">
                        <img src={logo} className='logo-footer m-auto' />
                    </div>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-lg-3">
                    <div className="w-100 text-white border-bottom border-3 border-white pt-2 pb-2">
                        Accesos rápidos
                    </div>
                    <div className="w-100 d-flex">
                        <div className="w-50 text-white">
                            <ul>
                                <li className="mt-2">
                                    <Link href="/" className="text-white text-decoration-none">Inicio</Link>
                                </li>
                                <li className="mt-2">
                                    <Link href="/training" className="text-white text-decoration-none">Formación</Link>
                                </li>
                                <li className="mt-2">
                                    <Link href="/library" className="text-white text-decoration-none">Biblioteca</Link>
                                </li>
                                <li className="mt-2">
                                    <Link href="/community" className="text-white text-decoration-none">Comunidad</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-50 text-white">
                            <ul>
                                <li className="mt-2">
                                    <Link href="/evangelism" className="text-white text-decoration-none">Evangelización</Link>
                                </li>
                                <li className="mt-2">
                                    <Link href="/donations" className="text-white text-decoration-none">Tienda de la Biblia</Link>
                                </li>
                                <li className="mt-2">
                                    <Link href="/help" className="text-white text-decoration-none">Ayuda</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-lg-2">
                    <div className="w-100 text-white border-bottom border-3 border-white pt-2 pb-2">
                        Legal
                    </div>
                    <div className="w-100 d-flex text-white">
                        <ul>
                            <li className="mt-2">Términos y condiciones</li>
                            <li className="mt-2">Política de privacidad</li>
                        </ul>
                    </div>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-lg-2">
                    <div className="w-100 text-white border-bottom border-3 border-white pt-2 pb-2">
                        Siguenos en nuestras redes
                    </div>
                    <div className="w-100 d-flex pt-3">
                        <button className="btn bg-white me-3 pt-2">
                            <ion-icon name="logo-youtube"></ion-icon>
                        </button>
                        <button className="btn bg-white me-3 pt-2">
                            <ion-icon name="logo-instagram"></ion-icon>
                        </button>
                        <button className="btn bg-white me-3 pt-2">
                            <ion-icon name="logo-facebook"></ion-icon>
                        </button>
                    </div>
                </div>
                <div className="col-1"></div>
            </div>
            <div className="row bg-dark">
                <div className="col-12 pt-3 pb-3">
                    <footer className="footer w-100 d-flex">
                        <p className="text-white ms-auto me-auto">Copyright 2025. Todos los derechos reservados a Fe en linea.</p>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default Footer;
