import React, { useEffect, useState } from 'react';
import useStore from '../../store/Store';

import utils from "../../utils/utils";

import MainLayout from "../../layouts/MainLayout/MainLayout";

import CardContentDescription from "../../components/ui/CardContentDescription/CardContentDescription";
import CardQuestionAndAnswer from "../../components/ui/CardQuestionAndAnswer/CardQuestionAndAnswer"
import CardProductExternal from "../../components/ui/CardProduct/CardProductExternal"
import SpyNavigation from "../../components/ui/SpyNavigation/SpyNavigation"
import CardProduct from "../../components/ui/CardProduct/CardProduct";
import HeaderCover from "../../components/ui/HeaderCover/HeaderCover"
import ContactForm from '../../components/ContactForm/ContactForm';

const HEADER_CONFIG = {
    type: "image", // solid, image,
    resource: "cover-home", // bg-secondary || cover-home
    icon: "",
    title: "¿Necesitas ayuda? \nEstamos aquí para acompañarte en \ncada paso de tu camino de fe.",
    showBorder: true,
    description: "",
    button: {
        show: false,
        text: "Participa en nuestros grupos",
        type: "btn",
        external: false,
        path: "/any-where"
    }
}

function Header() {
    return (
        <div className="container mt-5 mb-5">
            <CardContentDescription cards={[
                {
                    type: "success",
                    title: "¡Estamos Aquí para Ayudarte!\nConéctate con Nuestro Soporte en Vivo",
                    showBorder: true,
                    rightContent: true,
                    rightImage: "help-footer-resource",
                    subtitle: "",
                    description: "Si tienes alguna duda o necesitas ayuda, nuestro equipo de soporte está disponible para asistirte en tiempo real. Haz clic en el chat de asistencia en vivo y estaremos encantados de guiarte y resolver cualquier consulta que tengas. ¡No estás solo!",
                    button: {
                        show: true,
                        text: "Empieza tu misión hoy",
                        type: "btn",
                        external: false,
                        path: "/training"
                    }
                }
            ]} />
        </div>
    );
}

function Help() {
    const { config, forums } = useStore();

    const TAGS = [
        "Registro y Acceso",
        "Recursos y Comunidad",
        "Soporte y Donaciones",
    ]

    // "Registro y Acceso" "Recursos y Comunidad" "Soporte y Donaciones"
    const [tagQuestionActive, setTagQuestionActive] = useState(TAGS[0]);

    const handleChangeTag = (tag) => {
        setTagQuestionActive(tag);
    }

    const preProcessSpyNavigations = () => {
        return [
            { id: 'section1', title: 'Preguntas Frecuentes' },
            { id: 'section2', title: 'Formulario de contacto' },
            { id: 'section3', title: 'Chat de asistencia en vivo' }
        ]
    }

    const preProcessMain = () => {
        return [
            {
                type: "white",
                title: "Preguntas Frecuentes (FAQ)",
                showBorder: true,
                rightContent: false,
                subtitle: "",
                description: "Encuentra respuestas rápidas a las dudas más comunes sobre el uso del sitio Fe en Línea, incluyendo registro, recursos, cursos, y soporte.",
                button: {
                    show: false,
                    text: "",
                    type: "btn",
                    external: false,
                    path: "/any-where"
                }
            }
        ]
    }
    const preProcessContact = () => {
        return [
            {
                type: "transparent",
                title: "Contáctanos: Estamos aquí para ayudarte",
                showBorder: true,
                rightContent: false,
                subtitle: "",
                description: "Si tienes alguna pregunta o necesitas asistencia personalizada, por favor llena el siguiente formulario y nos pondremos en contacto contigo lo antes posible. ¡Estamos aquí para apoyarte en tu camino de fe!",
                button: {
                    show: false,
                    text: "",
                    type: "btn",
                    external: false,
                    path: "/any-where"
                }
            }
        ]
    }

    return (
        <MainLayout>

            <HeaderCover data={HEADER_CONFIG} />

            <SpyNavigation navigations={preProcessSpyNavigations()} />

            <div id="section1" className='container mt-5 mb-5'>
                <div className="row mt-4">
                    <div className="col-12 col-lg-5">
                        <CardContentDescription cards={preProcessMain()} />
                    </div>
                    <div className="col-12 col-lg-7">
                        <div className="container-fluid mb-3 border-bottom border-primary pb-4">
                            <div className="row">
                                {
                                    TAGS?.map(tag => {
                                        return (
                                            <div className="col-12 col-md-auto">
                                                <button onClick={() => { handleChangeTag(tag) }} className={tagQuestionActive == tag ? "btn btn-primary" : "btn btn-outline-primary"}>
                                                    {tag}
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            config?.data?.frequentlyAskedQuestions?.map(item => {
                                if (item?.tag == tagQuestionActive) {
                                    return (
                                        <div className="mb-3">
                                            <CardQuestionAndAnswer question={item?.question} answer={item?.answer} />
                                        </div>
                                    )
                                }
                                return <></>
                            })
                        }
                    </div>
                </div>
            </div>

            <div id="section2" className='container-fluid mt-5 mb-5 bg-secondary'>
                <div className="container">
                    <div className="row p-5">
                        <div className="col-12 col-lg-5 justify-content-center pb-4 d-flex">
                            <CardContentDescription cards={preProcessContact()} />
                        </div>
                        <div className="col-12 col-lg-7">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>

            <div id="section3" className='container mt-5 mb-5'>
                <Header />
            </div>

        </MainLayout>
    );
}

export default Help;
