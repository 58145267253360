import React, { useState } from "react";
import Swal from "sweetalert2";

const ContactForm = () => {
    // Estados para manejar los valores del formulario
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);

    // Función para manejar el envío del formulario
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validar que se acepten los términos y condiciones
        if (!acceptTerms) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Debes aceptar los términos y condiciones.",
            });
            return;
        }

        // Simular envío del formulario
        Swal.fire({
            icon: "success",
            title: "Mensaje enviado",
            text: "Tu mensaje ha sido enviado correctamente.",
        }).then(() => {
            // Limpiar el formulario
            setFullName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setAcceptTerms(false);
        });
    };

    return (
        <div className="container mt-5">
            <form onSubmit={handleSubmit} className="text-white">
                {/* Primera fila: Nombre completo y correo electrónico */}
                <div className="row mb-3">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <label>Nombre completo</label>
                        <input
                            type="text"
                            className="p-3 form-control placeholder-light2 bg-transparent text-white border-0 border-bottom border-white border-2 rounded-0"
                            placeholder="Ingresa tu nombre completo"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label>Correo Electrónico</label>
                        <input
                            type="email"
                            className="p-3 form-control placeholder-light2 bg-transparent text-white border-0 border-bottom border-white border-2 rounded-0"
                            placeholder="Ingresa tu correo electrónico"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {/* Segunda fila: Asunto */}
                <div className="row mb-3">
                    <div className="col-12">
                        <label>Asunto</label>
                        <input
                            type="text"
                            className="p-3 form-control placeholder-light2 bg-transparent text-white border-0 border-bottom border-white border-2 rounded-0"
                            placeholder="Ingresa el asunto de tu consulta"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {/* Tercera fila: Mensaje */}
                <div className="row mb-3">
                    <div className="col-12">
                        <label>Mensaje</label>
                        <textarea
                            className="p-3 form-control placeholder-light2 bg-transparent text-white border-0 border-bottom border-white border-2 rounded-0"
                            placeholder="Detalla tu consulta o comentario aquí"
                            rows="5"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                </div>

                {/* Checkbox de términos y condiciones */}
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="acceptTerms"
                                checked={acceptTerms}
                                onChange={(e) => setAcceptTerms(e.target.checked)}
                                required
                            />
                            <label className="form-check-label" htmlFor="acceptTerms">
                                Al enviar este formulario, aceptas nuestros Términos y Condiciones y Política de Privacidad.
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <button type="submit" className="btn btn-warning text-white">
                            Enviar mensaje
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;