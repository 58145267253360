import axios from "axios";

// Configuración de Axios
const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_HOST, // Base URL hasta el puerto
});

// Obtener cursos
const getCourses = async (page = 0, limit = 200) => {
    try {
        const response = await api.get("/api/faith-online/courses", {
            params: { page, limit },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching courses:", error);
        throw error;
    }
};

// Obtener webinars
const getWebinars = async (page = 0, limit = 200) => {
    try {
        const response = await api.get("/api/faith-online/webinars", {
            params: { page, limit },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching webinars:", error);
        throw error;
    }
};

// Obtener testimonios
const getTestimonials = async (page = 0, limit = 200) => {
    try {
        const response = await api.get("/api/faith-online/testimonials", {
            params: { page, limit },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching testimonials:", error);
        throw error;
    }
};

// Obtener recursos
const getResources = async (page = 0, limit = 200) => {
    try {
        const response = await api.get("/api/faith-online/resources", {
            params: { page, limit },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching resources:", error);
        throw error;
    }
};

// Obtener foros
const getForums = async (page = 0, limit = 200) => {
    try {
        const response = await api.get("/api/faith-online/forums", {
            params: { page, limit },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching forums:", error);
        throw error;
    }
};

// Obtener configuración
const getConfig = async () => {
    try {
        const response = await api.get("/api/faith-online/config");
        return response.data;
    } catch (error) {
        console.error("Error fetching config:", error);
        throw error;
    }
};

// Registro de usuario
const signUp = async (username, email, password, origin) => {
    try {
        const response = await api.post("/api/auth/register", {
            username,
            email,
            password,
            origin,
        });
        return response.data;
    } catch (error) {
        console.error("Error signing up:", error);
        throw error;
    }
};

// Inicio de sesión
const signIn = async (email, password) => {
    try {
        const response = await api.post("/api/auth/login", {
            email,
            password,
        });
        return response.data;
    } catch (error) {
        console.error("Error signing in:", error);
        throw error;
    }
};

// Obtener perfil del usuario
const getMyProfile = async (token) => {
    try {
        const response = await api.get("/api/users/my", {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching profile:", error);
        throw error;
    }
};

const apis = {
    getCourses,
    getWebinars,
    getTestimonials,
    getResources,
    getForums,
    signUp,
    signIn,
    getMyProfile,
    getConfig
}

export default apis;