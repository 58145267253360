import useStore from "../../store/Store";
import utils from "../../utils/utils";

import CardProductExternal from "../../components/ui/CardProduct/CardProductExternal"

function ExternalBookOfferContainer({ resources }) {
    const { config } = useStore();
    const randomResources = utils?.shuffleArray([...resources]).slice(0, 4);

    return (
        <div className="container mt-5 mb-5">
            <div className="row border-bottom border-primary">
                <div className="w-100 d-flex p-3">
                    <h5 className='mt-auto mb-auto me-auto'>Libros Físicos Disponibles en la Tienda de la Biblia</h5>
                    <button className="btn btn-secondary" onClick={() => window.open(config?.data?.externalLinkStore || "/", '_blank')}>
                        Ver más en Tienda de la Biblia
                    </button>
                </div>
            </div>
            <div className="row">
                {
                    randomResources?.map((resource) => (
                        <div className="col-12 col-md-6 col-lg-4 col-xxl-3 justify-content-center pt-4 pb-4">
                            <CardProductExternal card={resource} />
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default ExternalBookOfferContainer;