import React, { useRef, useState, useEffect } from 'react';

function ScrollableContainer({ children, className }) {
    const scrollRef = useRef(null);
    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        const checkScroll = () => {
            if (scrollRef.current) {
                const { scrollWidth, clientWidth } = scrollRef.current;
                setShowButtons(scrollWidth > clientWidth);
            }
        };

        checkScroll();
        window.addEventListener('resize', checkScroll);
        return () => window.removeEventListener('resize', checkScroll);
    }, [children]);

    const scroll = (direction) => {
        const container = scrollRef.current;
        const scrollAmount = 300;

        if (container) {
            container.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className={className}>
            <div
                ref={scrollRef}
                className="d-flex overflow-auto"
                style={{
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': { display: 'none' }
                }}
            >
                {children}
            </div>

            {showButtons && (
                <div className="d-flex justify-content-center gap-3 mt-3">
                    <button
                        className="btn bg-light2"
                        onClick={() => scroll('left')}
                    >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button
                        className="btn bg-light2"
                        onClick={() => scroll('right')}
                    >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                </div>
            )}
        </div>
    );
}

export default ScrollableContainer;