import ScrollableContainer from "../../layouts/ScrollableContainer/ScrollableContainer";

import CardContentDescription from "../../components/ui/CardContentDescription/CardContentDescription"
import CardProduct from "../../components/ui/CardProduct/CardProduct"

// mainContext = {title, description, textButton, link}
// simpleCards = {title, tag, img, description, textButton, link}

function WhiteSectionOverlappedBySimpleCards({ mainContext, simpleCards }) {
    const preProcessMain = () => {
        return [
            {
                type: "white",
                title: mainContext?.title,
                showBorder: true,
                rightContent: false,
                subtitle: mainContext?.subtitle,
                description: mainContext?.description,
                button: {
                    show: true,
                    text: mainContext?.textButton,
                    type: "btn",
                    external: false,
                    path: mainContext?.link
                }
            }
        ]
    }
    const preProcessCards = () => {
        return simpleCards?.map((card) => {
            return {
                bg: "border bg-white",
                img: card?.img,
                title: card?.title,
                tag: card?.tag,
                date: "",
                description: card?.description,
                buttonOne: {
                    show: true,
                    text: card?.textButton,
                    path: card?.link,
                    type: "w-100 mt-1 mb-1 btn btn-outline-secondary"
                },
                buttonTwo: {
                    show: false,
                    text: "Leer en línea",
                    type: "w-100 mt-1 mb-1 btn btn-secondary",
                    path: "/any-where"
                }
            }
        })
    }
    return <div className="container mt-5 mb-5">
        <div className="row">
            <div className="col-12 col-md-5 d-flex align-items-center pb-5">
                <CardContentDescription cards={preProcessMain()} />
            </div>
            <ScrollableContainer className="col-12 col-md-7">
                <>
                    {
                        preProcessCards()?.map((card) => {
                            return (
                                <div className="ms-2 me-2">
                                    <CardProduct card={card} />
                                </div>
                            )
                        })
                    }
                </>
            </ScrollableContainer>
        </div>
    </div>
}

export default WhiteSectionOverlappedBySimpleCards;