import React, { useState } from 'react';

import bookClosePrimary from "../../../assets/book-close-primary.png"
import bookOpenPrimary from "../../../assets/book-open-primary.png"

function CardQuestionAndAnswer({ question, answer }) {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        setOpen(!open)
    }
    return (
        <div className="w-100 card card-body rounded p-4">
            <div onClick={toggleOpen} className="w100 d-flex" style={{ cursor: "pointer" }}>
                <h5>{question}</h5>
                
                <div className='ms-auto'>
                    <img src={open ? bookOpenPrimary : bookClosePrimary} style={{ width: open ? 35 : 25 }} />
                </div>
            </div>
            <div className="w-100 border-top mt-3 pt-3" style={{ display: open ? "block" : "none" }}>
                <p>{answer}</p>
            </div>
        </div>
    );
}

export default CardQuestionAndAnswer;