import MainLayout from "../layouts/MainLayout/MainLayout";

import CardQuestionAndAnswer from "../components/ui/CardQuestionAndAnswer/CardQuestionAndAnswer"

function Lab() {
  return (
    <MainLayout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-5 p-0">
            <CardQuestionAndAnswer question={"¿Cómo puedo registrarme en el sitio?"} answer={'Para registrarte, solo haz clic en el botón "Registrarse" en la esquina superior derecha de la pantalla de inicio. Completa el formulario con tus datos personales y sigue las instrucciones para crear tu cuenta.'} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Lab;
