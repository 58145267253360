import React, { useState } from "react";
import { Link, useLocation } from "wouter";
import Swal from "sweetalert2";
import useStore from "../../../store/Store.js";
import logo from "../../../assets/logo.png";
import logo2 from "../../../assets/logo2.png";

const DICTIONARY_THEMES = {
  "/library": "white",
  "/training": "white",
  "/migrant": "secondary",
  "/new-beginning": "secondary",
  "/build-your-faith": "secondary",
  "/evangelizer-and-missionary": "secondary",
};

function Navbar() {
  const { isAuth, config, userProfile, loginUser, registerUser } = useStore();
  const location = useLocation()[0];
  const theme = DICTIONARY_THEMES[location] || "primary";

  // Estados para el modal y los formularios
  const [showModal, setShowModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [isLogin, setIsLogin] = useState(true); // true: login, false: registro
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  // Manejar el envío del formulario de login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(email, password);
      Swal.fire({
        icon: "success",
        title: "¡Bienvenido!",
        text: "Has iniciado sesión correctamente.",
      });
      setShowModal(false); // Cerrar el modal después del login exitoso
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Correo o contraseña incorrectos.",
      });
    }
  };

  // Manejar el envío del formulario de registro
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await registerUser(username, email, password, "web");
      Swal.fire({
        icon: "success",
        title: "¡Registro exitoso!",
        text: "Tu cuenta ha sido creada correctamente.",
      });
      setIsLogin(true); // Cambiar a la vista de login después del registro
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al registrar tu cuenta.",
      });
    }
  };

  // Estilos dinámicos
  const getBgCollapseStyleClass = () => {
    if (theme === "primary") return "#1998B6";
    if (theme === "secondary") return "#024975";
    return "#fff";
  };

  const getNavbarStyleClass = () => {
    if (theme === "primary") return "navbar navbar-expand-lg navbar-dark bg-primary";
    if (theme === "secondary") return "navbar navbar-expand-lg navbar-dark bg-secondary";
    return "navbar navbar-expand-lg navbar-light bg-white";
  };

  const getNavLinkStyleClass = (path) => {
    const target = location === path;
    if (theme === "primary" || theme === "secondary") {
      return `btn nav-link active ${target ? "border-bottom border-3" : ""}`;
    }
    return `btn nav-link active text-secondary ${target ? "border-bottom border-3 border-secondary" : ""}`;
  };

  const getButtonStyleClass = () => {
    if (theme === "primary") return "w-100 btn btn-secondary pt-2 pb-2 m-auto d-flex align-items-center justify-content-center";
    if (theme === "secondary") return "w-100 btn btn-primary pt-2 pb-2 m-auto d-flex align-items-center justify-content-center";
    return "w-100 btn btn-outline-secondary pt-2 pb-2 m-auto d-flex align-items-center justify-content-center";
  };

  return (
    <nav className={getNavbarStyleClass()}>
      <div className="container" style={{ backgroundColor: getBgCollapseStyleClass() }}>
        <Link className="navbar-brand" href="/">
          <img src={theme === "primary" || theme === "secondary" ? logo : logo2} className="logo" alt="Logo" height="30" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item me-2">
              <Link className={getNavLinkStyleClass("/")} href="/">
                Inicio {location === '/' && <span className="visually-hidden">(current)</span>}
              </Link>
            </li>
            <li className="nav-item me-2">
              <Link className={getNavLinkStyleClass("/training")} href="/training">
                Formación {location === '/training' && <span className="visually-hidden">(current)</span>}
              </Link>
            </li>
            <li className="nav-item me-2">
              <Link className={getNavLinkStyleClass("/library")} href="/library">
                Biblioteca {location === '/library' && <span className="visually-hidden">(current)</span>}
              </Link>
            </li>
            <li className="nav-item me-2">
              <Link className={getNavLinkStyleClass("/community")} href="/community">
                Comunidad {location === '/community' && <span className="visually-hidden">(current)</span>}
              </Link>
            </li>
            <li className="nav-item me-2">
              <Link className={getNavLinkStyleClass("/evangelism")} href="/evangelism">
                Evangelización {location === '/evangelism' && <span className="visually-hidden">(current)</span>}
              </Link>
            </li>
            <li className="nav-item me-2">
              <a className={getNavLinkStyleClass("/donations")} onClick={() => window.open(config?.data?.externalLinkStore || "/", '_blank')}>
                Tienda de la Biblia {location === '/donations' && <span className="visually-hidden">(current)</span>}
              </a>
            </li>
            <li className="nav-item me-2">
              <Link className={getNavLinkStyleClass("/help")} href="/help">
                Ayuda {location === '/help' && <span className="visually-hidden">(current)</span>}
              </Link>
            </li>
          </ul>
          <div className="d-flex">
            {isAuth ? (
              <button onClick={() => setShowProfile(true)} className={getButtonStyleClass()}>
              <ion-icon name="person-circle-outline" className="me-2"></ion-icon>
              {userProfile?.username}
            </button>
            ) : (
              <button className={getButtonStyleClass()} onClick={() => setShowModal(true)}>
                Acceder ahora
              </button>
            )}
          </div>
        </div>
      </div>

      <div 
        className={`offcanvas offcanvas-end ${showProfile ? 'show' : ''}`} 
        tabIndex="-1" 
        style={{ visibility: showProfile ? 'visible' : 'hidden' }}
      >
        <div className="offcanvas-header bg-secondary text-white">
          <h5 className="offcanvas-title">Mi Perfil</h5>
          <button 
            type="button" 
            className="btn-close btn-close-white" 
            onClick={() => setShowProfile(false)}
          ></button>
        </div>
        <div className="offcanvas-body bg-info">
          <div className="text-center mb-4">
            <div className="bg-secondary text-white rounded-circle d-inline-flex align-items-center justify-content-center mb-3" 
                 style={{ width: '100px', height: '100px' }}>
              <ion-icon name="person" style={{ fontSize: '3rem' }}></ion-icon>
            </div>
            <h3 className="text-secondary">{userProfile?.username}</h3>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-muted">Información de cuenta</h6>
              <div className="mb-2">
                <small className="text-muted">Email</small>
                <p className="mb-0">{userProfile?.email}</p>
              </div>
              <div className="mb-2">
                <small className="text-muted">Rol</small>
                <p className="mb-0 text-capitalize">{userProfile?.role}</p>
              </div>
              <div className="mb-2">
                <small className="text-muted">Origen</small>
                <p className="mb-0">{userProfile?.origin}</p>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-muted">Estadísticas</h6>
              <div className="mb-2">
                <small className="text-muted">Favoritos guardados</small>
                <p className="mb-0">{userProfile?.favorites?.length || 0}</p>
              </div>
              <div className="mb-2">
                <small className="text-muted">Suscripciones activas</small>
                <p className="mb-0">{userProfile?.subscriptions?.length || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showProfile && (
        <div 
          className="offcanvas-backdrop fade show" 
          onClick={() => setShowProfile(false)}
        ></div>
      )}

      {/* Modal de Login/Registro */}
      <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }} tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isLogin ? "Iniciar Sesión" : "Registrarse"}</h5>
              <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {isLogin ? (
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Correo electrónico
                    </label>
                    <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Contraseña
                    </label>
                    <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Iniciar Sesión
                  </button>
                </form>
              ) : (
                <form onSubmit={handleRegister}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Nombre de usuario
                    </label>
                    <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Correo electrónico
                    </label>
                    <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Contraseña
                    </label>
                    <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Registrarse
                  </button>
                </form>
              )}
              <div className="text-center mt-3">
                <button className="btn btn-link" onClick={() => setIsLogin(!isLogin)}>
                  {isLogin ? "¿No tienes una cuenta? Regístrate" : "¿Ya tienes una cuenta? Inicia Sesión"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show" onClick={() => setShowModal(false)}></div>}
    </nav>
  );
}

export default Navbar;