import { Link, useLocation } from "wouter";

import useStore from "../../store/Store";
import utils from "../../utils/utils";

import CardProductExternal from "../../components/ui/CardProduct/CardProductExternal"
import CardProduct from "../ui/CardProduct/CardProduct";

function InternalBookOfferContainer({ resources }) {
    const { config } = useStore();
    const randomResources = utils?.shuffleArray([...resources]).slice(0, 4);

    return (
        <div className="container mt-5 mb-5">
            <div className="row border-bottom border-primary">
                <div className="w-100 d-flex p-3">
                    <h5 className='mt-auto mb-auto me-auto'>Libros y Devocionales Gratis</h5>
                    <Link className="btn btn-secondary" href="/library">
                        Ir a la Biblioteca
                    </Link>
                </div>
            </div>
            <div className="row">
                {
                    randomResources?.map(item => ({
                        bg: "border bg-white",
                        img: item?.image,
                        title: item?.title,
                        tag: item?.tag,
                        date: item?.creationDate,
                        description: "",
                        buttonOne: {
                            show: true,
                            text: "Descargar",
                            type: "w-100 mt-1 mb-1 btn btn-outline-secondary",
                            path: item?.link
                        },
                        buttonTwo: {
                            show: true,
                            text: "Leer en línea",
                            type: "w-100 mt-1 mb-1 btn btn-secondary",
                            path: item?.link
                        },
                        type: item?.type,
                        cost: item?.cost
                    }))?.map((resource) => (
                        <div className="col-12 col-md-6 col-lg-4 col-xxl-3 justify-content-center pt-4 pb-4">
                            <CardProduct card={resource} />
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default InternalBookOfferContainer;