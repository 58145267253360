import React, { useEffect, useState } from 'react';
import useStore from '../../store/Store'

import utils from "../../utils/utils";

import MainLayout from "../../layouts/MainLayout/MainLayout";
import ScrollableContainer from "../../layouts/ScrollableContainer/ScrollableContainer";

import CardContentDescription from "../../components/ui/CardContentDescription/CardContentDescription"
import SpyNavigation from "../../components/ui/SpyNavigation/SpyNavigation"
import CardProduct from "../../components/ui/CardProduct/CardProduct"

import borderPrimary from "../../assets/border-primary.png"

const HEADER_CONFIG = [
    {
        type: "success",
        title: "La mejor inversión en tu vida es fortalecer tu fe. ¡Empieza hoy tu formación bíblica!",
        showBorder: true,
        rightContent: true,
        rightImage: "formation-resource",
        subtitle: "",
        description: "",
        button: {
            show: true,
            text: "Inscríbete ahora",
            type: "btn",
            external: false,
            path: "http://home.campusbiblico.com/form-inscripcion/"
        }
    }
]

function Header() {
    return (
        <div className="container mt-5 mb-5">
            <CardContentDescription cards={HEADER_CONFIG} />
        </div>
    )
}

function CoursesAndTrainingSection({ courses }) {
    const LIMIT = 4;
    const [filterText, setFilterText] = useState("");
    const [filterTag, setFilterTag] = useState("Ver todo");
    const [page, setPage] = useState(1);

    // Filter courses based on text and tag
    const filteredCourses = courses?.filter(course => {
        const normalizedFilterText = utils?.normalizeText(filterText.toLowerCase());
        const normalizedTitle = utils?.normalizeText(course.title?.toLowerCase() || "");
        const normalizedDescription = utils?.normalizeText(course.description?.toLowerCase() || "");
    
        const matchesText = filterText === "" ||
            normalizedTitle.includes(normalizedFilterText) ||
            normalizedDescription.includes(normalizedFilterText);
    
        const matchesTag = filterTag === "Ver todo" || course.tag === filterTag;
    
        return matchesText && matchesTag;
    });

    // Reset page when filters change
    useEffect(() => {
        setPage(1);
    }, [filterText, filterTag]);

    const totalPages = Math.ceil((filteredCourses?.length || 0) / LIMIT);
    const hasNextPage = page < totalPages;
    const hasPrevPage = page > 1;

    const handleChangeFilter = (e) => {
        setFilterText(e.target.value);
    }

    const handleSetTag = (tag) => {
        setFilterTag(tag);
    }

    const handleNextPage = () => {
        if (hasNextPage) {
            setPage(prev => prev + 1);
        }
    };

    const handlePrevPage = () => {
        if (hasPrevPage) {
            setPage(prev => prev - 1);
        }
    };

    const paginatedCourses = filteredCourses?.slice((page - 1) * LIMIT, page * LIMIT);

    return (
        <div id="section1" className='container-fluid mt-5'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="fw-bold">Cursos y formación</h2>
                        <img src={borderPrimary} style={{ width: 55 }} />
                    </div>
                    <div className="col-12 col-md-2 col-xxl-auto d-flex pb-3 justify-content-center mt-2">
                        <button onClick={() => { handleSetTag("Ver todo") }} className={filterTag == "Ver todo" ? 'btn btn-primary m-auto' : 'btn btn-outline-primary m-auto'}>Ver todo</button>
                    </div>
                    <div className="col-12 col-md-2 col-xxl-auto d-flex pb-3 justify-content-center mt-2">
                        <button onClick={() => { handleSetTag("Fe básica") }} className={filterTag == "Fe básica" ? 'btn btn-primary m-auto' : 'btn btn-outline-primary m-auto'}>Fe básica</button>
                    </div>
                    <div className="col-12 col-md-2 col-xxl-auto d-flex pb-3 justify-content-center mt-2">
                        <button onClick={() => { handleSetTag("Teología") }} className={filterTag == "Teología" ? 'btn btn-primary m-auto' : 'btn btn-outline-primary m-auto'}>Teología</button>
                    </div>
                    <div className="col-12 col-md-2 col-xxl-auto d-flex pb-3 justify-content-center mt-2">
                        <button onClick={() => { handleSetTag("Liderazgo") }} className={filterTag == "Liderazgo" ? 'btn btn-primary m-auto' : 'btn btn-outline-primary m-auto'}>Liderazgo</button>
                    </div>
                    <div className="col-12 col-md-2 col-xxl-auto d-flex pb-3 justify-content-center mt-2">
                        <button onClick={() => { handleSetTag("Evangelización") }} className={filterTag == "Evangelización" ? 'btn btn-primary m-auto' : 'btn btn-outline-primary m-auto'}>Evangelización</button>
                    </div>
                    <div className="col-12 col-md-12 col-xxl-6 d-flex mt-2 form-floating mb-3 ps-1 pe-1">
                        <input onChange={handleChangeFilter} type="text" className="form-control" id="floatingInput" placeholder="Busca un curso por nombre, categoría o palabra clave..." />
                        <label for="floatingInput">Busca un curso por nombre o descripción</label>
                    </div>
                </div>
                <div className="row">
                    {
                        paginatedCourses?.map(card => {
                            return (
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-2">
                                    <CardProduct card={card} />
                                </div>
                            )
                        })
                    }
                </div>
                {totalPages > 1 && (
                    <div className="row mt-4">
                        <div className="col-12 d-flex justify-content-center gap-3">
                            <button
                                className="btn btn-outline-primary"
                                onClick={handlePrevPage}
                                disabled={!hasPrevPage}
                            >
                                <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <div className="d-flex align-items-center">
                                <span className="fw-bold">Página {page} de {totalPages}</span>
                            </div>
                            <button
                                className="btn btn-outline-primary"
                                onClick={handleNextPage}
                                disabled={!hasNextPage}
                            >
                                <ion-icon name="chevron-forward-outline"></ion-icon>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

function WebinarCalendarSection({ webinars }) {
    return (
        <div id="section2" className='container-fluid mt-5 mb-5'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="fw-bold">Calendario de Webinars</h2>
                        <img src={borderPrimary} style={{ width: 55 }} />
                        <p className='mt-3'>Nuestros webinars integran una sólida base teórica con aplicaciones prácticas, preparándote para enfrentar los desafíos del mundo actual. Este es el momento perfecto para profundizar en el conocimiento de la Palabra de Dios y servir con propósito, desde la comodidad de tu hogar.</p>
                    </div>
                    <ScrollableContainer className="col-12 mt-5">
                        {
                            webinars?.map((card, index) => {
                                return (
                                    <div className="ms-2 me-2">
                                        <CardProduct card={card} />
                                    </div>
                                )
                            }
                            )
                        }
                    </ScrollableContainer>
                </div>
            </div>
        </div>
    )
}

function Training() {
    const { courses, webinars } = useStore();

    const preProcessSpyNavigations = () => {
        return [
            { id: 'section1', title: 'Cursos y formación' },
            { id: 'section2', title: 'Calendario de Webinars' }
        ]
    }

    const preProcessCourses = () => {
        return courses?.data?.map((course) => {
            return {
                bg: "border bg-white",
                img: course?.image,
                title: course?.title,
                tag: course?.tag,
                date: "",
                description: course?.description,
                buttonOne: {
                    show: true,
                    text: "Inscribirme",
                    type: "w-100 mt-1 mb-1 btn btn-outline-secondary",
                    path: course?.link
                },
                buttonTwo: {
                    show: false,
                    text: "Leer en línea",
                    type: "w-100 mt-1 mb-1 btn btn-secondary",
                    path: "/any"
                }
            }
        })
    }

    const preProcessWebinars = () => {
        return webinars?.data?.map((item) => {
            return {
                bg: "border bg-light2",
                img: "",
                title: item?.title,
                tag: "",
                date: item?.date,
                description: item?.description,
                buttonOne: {
                    show: true,
                    text: "Quiero saber más",
                    type: "w-100 mt-1 mb-1 btn btn-warning text-white",
                    path: item?.link
                },
                buttonTwo: {
                    show: false,
                    text: "Leer en línea",
                    type: "w-100 mt-1 mb-1 btn btn-secondary",
                    path: "/any"
                }
            }
        })
    }

    return (
        <MainLayout>

            <Header />

            <SpyNavigation navigations={preProcessSpyNavigations()} />

            <CoursesAndTrainingSection courses={preProcessCourses()} />

            <WebinarCalendarSection webinars={preProcessWebinars()} />

        </MainLayout>
    );
}

export default Training;
