import { useState } from "react"
import { Link } from 'wouter';
import utils from "../../../utils/utils";

import borderPrimary from "../../../assets/border-primary.png"
import borderWhite from "../../../assets/border-white.png"

import evangelizerAndMissionaryFooterResource from "../../../assets/EvangelizerAndMissionary-footer-resource.png"
import buildYourFaithFooterResource from "../../../assets/buildYourFaith-footer-resource.png"
import migrantFooterResource from "../../../assets/migrant-footer-resource.png"
import helpFooterResource from "../../../assets/help-footer-resource.png"
import formationResource from "../../../assets/formation-resourse.png"
import bookResource from "../../../assets/book-resourse.png"
import handResource from "../../../assets/hand-resourse.png"

const DICTIONARY_IMAGES = {
    "evangelizer-and-missionary-footer-resource": evangelizerAndMissionaryFooterResource,
    "build-your-faith-footer-resource": buildYourFaithFooterResource,
    "migrant-footer-resource": migrantFooterResource,
    "help-footer-resource": helpFooterResource,
    "formation-resource": formationResource,
    "book-resource": bookResource,
    "hand-resource": handResource,
}

const THEME = {
    "transparent": {
        "img": borderWhite,
        "card": "bg-transparent m-auto",
        "title": "mb-0 text-white f-playfair fw-bold",
        "subtitle": "text-white fw-bold mb-2 mt-2",
        "description": "text-white mb-2 mt-2",
        "button": {
            "btn": "btn bg-warning text-white fw-bold mt-3",
            "link": "btn bg-success text-white border-bottom ps-0 fw-bold pt-3"
        }
    },
    "success": {
        "img": borderPrimary,
        "card": "bg-success",
        "title": "mb-0 text-secondary f-playfair fw-bold",
        "subtitle": "text-secondary fw-bold mb-2 mt-2",
        "description": "text-secondary mb-2 mt-2",
        "button": {
            "btn": "btn bg-warning text-white fw-bold mt-3",
            "link": "btn bg-success text-secondary border-bottom ps-0 fw-bold pt-3"
        }
    },
    "secondary": {
        "img": borderWhite,
        "card": "bg-secondary",
        "title": "mb-0 text-white f-playfair fw-bold",
        "subtitle": "text-white fw-bold mb-2 mt-2",
        "description": "text-white mb-2 mt-2",
        "button": {
            "btn": "btn bg-white text-white fw-bold mt-3",
            "link": "btn bg-secondary text-white border-bottom ps-0 fw-bold pt-3"
        }
    },
    "white": {
        "img": borderPrimary,
        "card": "bg-white",
        "title": "mb-0 text-secondary f-playfair fw-bold",
        "subtitle": "text-secondary fw-bold mb-2 mt-2",
        "description": "text-secondary mb-2 mb-2 mt-2",
        "button": {
            "btn": "btn bg-primary text-white fw-bold mt-3",
            "link": "btn bg-white text-secondary border-bottom ps-0 fw-bold pt-3"
        }
    },
}

function CardContentDescription({ cards }) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const formattedIndex = String(currentIndex + 1).padStart(2, '0');
    const totalCards = String(cards?.length).padStart(2, '0');

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? cards?.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === cards?.length - 1 ? 0 : prevIndex + 1
        );
    };

    const getTheme = (key, subkey) => {
        const themePivot = THEME[cards[currentIndex]?.type]
        if (key == "button") {
            const button = themePivot[key]
            return button[subkey]
        }
        return themePivot[key];
    }

    const handleClick = () => {
        const button = cards[currentIndex]?.button
        console.log(button)
    }

    if (!cards || cards?.length === 0) return <div>not found</div>;

    return (
        <div className={getTheme("card")} style={{ borderRadius: 22 }}>
            <div className="container-fluid">
                <div className="row">
                    <div className={cards[currentIndex]?.rightContent ? "col-12 col-lg-8 " + getTheme("card") : "col-12 " + getTheme("card")} style={{ borderRadius: 22, padding: 32, zIndex: 1000 }}>
                        {
                            cards[currentIndex]?.title?.length > 0 &&
                            <h2 className={getTheme("title")}>{cards[currentIndex]?.title}</h2>
                        }
                        {
                            cards[currentIndex]?.showBorder &&
                            <img src={getTheme("img")} style={{ width: 55 }} />
                        }
                        {
                            cards[currentIndex]?.subtitle?.length > 0 &&
                            <p className={getTheme("subtitle")}>{cards[currentIndex]?.subtitle}</p>
                        }
                        {
                            cards[currentIndex]?.description?.length > 0 &&
                            <p className={getTheme("description")}>{cards[currentIndex]?.description}</p>
                        }
                        {
                            cards[currentIndex]?.button?.show && <div>
                                {
                                    cards[currentIndex]?.button?.type === "btn" ? (
                                        // Si el tipo de botón es "btn"
                                        utils?.isExternalLink(cards[currentIndex]?.button?.path) ? (
                                            // Enlace externo: abre en una nueva pestaña
                                            <a
                                                href={cards[currentIndex]?.button?.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={getTheme("button", cards[currentIndex]?.button?.type)}
                                            >
                                                <p>{cards[currentIndex]?.button?.text}</p>
                                            </a>
                                        ) : (
                                            // Ruta relativa: usa wouter para la navegación
                                            <Link to={cards[currentIndex]?.button?.path}>
                                                <button
                                                    onClick={handleClick}
                                                    className={getTheme("button", cards[currentIndex]?.button?.type)}
                                                >
                                                    <p>{cards[currentIndex]?.button?.text}</p>
                                                </button>
                                            </Link>
                                        )
                                    ) : (
                                        // Si el tipo de botón no es "btn"
                                        utils?.isExternalLink(cards[currentIndex]?.button?.path) ? (
                                            // Enlace externo: abre en una nueva pestaña
                                            <a
                                                href={cards[currentIndex]?.button?.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={getTheme("button", cards[currentIndex]?.button?.type)}
                                            >
                                                <p>{cards[currentIndex]?.button?.text}</p>
                                            </a>
                                        ) : (
                                            // Ruta relativa: usa wouter para la navegación
                                            <Link to={cards[currentIndex]?.button?.path}>
                                                <button
                                                    onClick={handleClick}
                                                    className={getTheme("button", cards[currentIndex]?.button?.type)}
                                                >
                                                    <p>{cards[currentIndex]?.button?.text}</p>
                                                </button>
                                            </Link>
                                        )
                                    )
                                }
                            </div>
                        }
                    </div>
                    {
                        cards[currentIndex]?.rightContent &&
                        <div className="col-12 col-lg-4 d-flex position-relative">
                            <img src={DICTIONARY_IMAGES[cards[currentIndex]?.rightImage]} style={{
                                marginTop: "-2rem",
                                marginLeft: "auto",
                                width: "100%",
                                position: "relative"
                            }} />
                        </div>
                    }
                </div>
            </div>
            {
                cards?.length > 1 &&
                <div className="w-100 d-flex mt-3" style={{ padding: 32, paddingTop: 0 }}>
                    <div className="me-auto mt-auto mb-auto bg-light2 p-2 d-flex rounded">
                        <p className='mb-0 me-1'>
                            {formattedIndex}
                        </p>
                        {" / "}
                        <p className='mb-0 ms-1 text-primary'>
                            {totalCards}
                        </p>
                    </div>
                    <div className="flex gap-4">
                        <button
                            onClick={handlePrevious}
                            className="btn bg-light2 me-2"
                        >
                            <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button
                            onClick={handleNext}
                            className="btn bg-light2"
                        >
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}

export default CardContentDescription;
