import useStore from '../../../store/Store'

import MainLayout from "../../../layouts/MainLayout/MainLayout";

import CoverButtons from "../../../components/ui/CoverButtons/CoverButtons"
import HeaderCover from "../../../components/ui/HeaderCover/HeaderCover"

import Testimonial from "./Testimonial/Testimonial";
import Footer from "./Footer/Footer";

import WhiteSectionOverlappedBySimpleCards from "../../../components/WhiteSectionOverlappedBySimpleCards/WhiteSectionOverlappedBySimpleCards"
import BlueSectionOverlappedBySimpleCards from "../../../components/BlueSectionOverlappedBySimpleCards/BlueSectionOverlappedBySimpleCards";

// NEKO NOTE - agregar useMemo y useCallback
function Home() {
  const { config, courses, webinars, testimonials } = useStore();

  const preProcessHeaders = () => {
    return {
      type: "image", // solid, image,
      resource: "cover-home", // bg-secondary || cover-home
      icon: "",
      title: "Tu fe es un camino, no un destino. \nDescubre, aprende y crece con nosotros en cada paso.",
      showBorder: false,
      description: "",
      button: {
        show: false,
        text: "algo",
        type: "btn",
        external: false,
        path: "/any-where"
      }
    }
  }

  const preProcessWhiteSection = () => {
    return {
      mainContext: {
        title: "Programas de fe",
        description: "Programas de impacto que transforman comunidades de fe, empoderando a sus miembros para crecer juntos en espiritualidad, unidad y acción.",
        textButton: "Ver toda la formación",
        link: "/training"
      },
      simpleCards: courses?.data?.map(item => {
        return {
          title: item?.title,
          tag: item?.tag,
          img: item?.image,
          description: item?.description,
          textButton: "Inscribirme",
          link: item?.link
        }
      })
    }
  }

  const preProcessBlueSection = () => {
    return {
      mainContext: {
        title: "Webinars",
        subtitle: "¡No te pierdas nuestras clases magistrales gratuitas!",
        description: "Cada viernes, ofrecemos Webinars con expertos en Biblia que compartirán su conocimiento y experiencia en áreas clave de los diplomados que ofrecemos. Las Master Class son una excelente oportunidad para profundizar en temas bíblicos y descubrir más sobre lo que aprenderás en nuestros diplomados.",
        textButton: "Ver todos los webinars",
        link: config?.data?.externalLinkWebinars || "/"
      },
      simpleCards: webinars?.data?.map(item => {
        return {
          title: item?.title,
          date: item?.date,
          description: item?.description,
          textButton: "Quiero saber más",
          link: item?.link
        }
      })
    }
  }

  const preProcessTestimonials = () => {
    return testimonials?.data
  }

  if (!config?.data) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'var(--light2)' // Fondo light2
      }}>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <MainLayout>

      <HeaderCover data={preProcessHeaders()} />

      <CoverButtons />

      <WhiteSectionOverlappedBySimpleCards mainContext={preProcessWhiteSection()?.mainContext} simpleCards={preProcessWhiteSection()?.simpleCards} />

      <BlueSectionOverlappedBySimpleCards mainContext={preProcessBlueSection()?.mainContext} simpleCards={preProcessBlueSection()?.simpleCards} />

      <Testimonial testimonials={preProcessTestimonials()} />

      <Footer />
    </MainLayout>
  );
}

export default Home;
