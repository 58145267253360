import React from "react";
import styled, { keyframes } from "styled-components";

// Animación del spinner
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Estilos del overlay
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); // Fondo blanco al 50% de opacidad
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Asegura que esté por encima de todo
`;

// Estilos del spinner
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000; // Color del spinner
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

// Componente LoadingOverlay
const LoadingOverlay = () => {
    return (
        <Overlay>
            <Spinner />
        </Overlay>
    );
};

export default LoadingOverlay;