import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import api from "../api/api"

const useStore = create(
    persist(
        (set, get) => ({
            // Estado inicial
            isLoading: false,
            isAuth: false,
            config: {},
            token: "",
            courses: [],
            webinars: [],
            testimonials: [],
            resources: [],
            forums: [],
            userProfile: null,
            captureInteractionsList: [],

            // Acciones para la API
            fetchConfig: async () => {
                set({ isLoading: true });
                try {
                    const config = await api.getConfig();
                    set({ config, isLoading: false });
                } catch (error) {
                    console.error("Error fetching config:", error);
                    set({ isLoading: false });
                }
            },

            fetchCourses: async (page = 0, limit = 500) => {
                set({ isLoading: true });
                try {
                    const courses = await api.getCourses(page, limit);
                    set({ courses, isLoading: false });
                } catch (error) {
                    console.error("Error fetching courses:", error);
                    set({ isLoading: false });
                }
            },

            fetchWebinars: async (page = 0, limit = 500) => {
                set({ isLoading: true });
                try {
                    const webinars = await api.getWebinars(page, limit);
                    set({ webinars, isLoading: false });
                } catch (error) {
                    console.error("Error fetching webinars:", error);
                    set({ isLoading: false });
                }
            },

            fetchTestimonials: async (page = 0, limit = 500) => {
                set({ isLoading: true });
                try {
                    const testimonials = await api.getTestimonials(page, limit);
                    set({ testimonials, isLoading: false });
                } catch (error) {
                    console.error("Error fetching testimonials:", error);
                    set({ isLoading: false });
                }
            },

            fetchResources: async (page = 0, limit = 500) => {
                set({ isLoading: true });
                try {
                    const resources = await api.getResources(page, limit);
                    set({ resources, isLoading: false });
                } catch (error) {
                    console.error("Error fetching resources:", error);
                    set({ isLoading: false });
                }
            },

            fetchForums: async (page = 0, limit = 500) => {
                set({ isLoading: true });
                try {
                    const forums = await api.getForums(page, limit);
                    set({ forums, isLoading: false });
                } catch (error) {
                    console.error("Error fetching forums:", error);
                    set({ isLoading: false });
                }
            },

            registerUser: async (username, email, password, origin) => {
                set({ isLoading: true });
                try {
                    const response = await api.signUp(username, email, password, origin);
                    set({ isLoading: false });
                    return response; // Devuelve la respuesta para manejar el éxito fuera del store
                } catch (error) {
                    console.error("Error registering user:", error);
                    set({ isLoading: false });
                    throw error; // Lanza el error para manejarlo fuera del store
                }
            },

            loginUser: async (email, password) => {
                set({ isLoading: true });
                try {
                    const response = await api.signIn(email, password);
                    set({ token: response.token, isLoading: false, isAuth: true });
                    return response; // Devuelve la respuesta para manejar el éxito fuera del store
                } catch (error) {
                    console.error("Error logging in:", error);
                    set({ isLoading: false });
                    throw error; // Lanza el error para manejarlo fuera del store
                }
            },

            fetchUserProfile: async () => {
                set({ isLoading: true });
                try {
                    const { token } = get();
                    const profile = await api.getMyProfile(token);
                    set({ userProfile: profile, isLoading: false });
                } catch (error) {
                    console.error("Error fetching user profile:", error);
                    set({ isLoading: false });
                }
            },

            captureInteraction: async (objetCapture, eventType) => {
                const { isAuth, token, captureInteractionsList } = get();
                const newCapture = {
                    objetCapture,
                    eventType,
                    dateCapture: new Date().toISOString()
                };

                if (isAuth) {
                    try {
                        const captures = [...captureInteractionsList, newCapture];
                        await api.captureInteractions(captures, token);
                        set({ captureInteractionsList: [] }); // Clear list after successful send
                    } catch (error) {
                        console.error("Error capturing interaction:", error);
                        set({ captureInteractionsList: [...captureInteractionsList, newCapture] });
                    }
                } else {
                    set({ captureInteractionsList: [...captureInteractionsList, newCapture] });
                }
            }
        }),
        {
            name: "neko-store", // Nombre único para el almacenamiento en localStorage
        }
    )
);

export default useStore;