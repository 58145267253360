import React, { useEffect, useState } from 'react';
import useStore from '../../../store/Store'

import { Link } from 'wouter'

import utils from '../../../utils/utils';

import MainLayout from "../../../layouts/MainLayout/MainLayout";
import ScrollableContainer from "../../../layouts/ScrollableContainer/ScrollableContainer";

import CardContentDescription from "../../../components/ui/CardContentDescription/CardContentDescription"
import SpyNavigation from "../../../components/ui/SpyNavigation/SpyNavigation"
import CardJoinGroup from "../../../components/ui/CardJoinGroup/CardJoinGroup"
import HeaderCover from "../../../components/ui/HeaderCover/HeaderCover"

import coverNewBeginning from "../../../assets/cover-new-beginnning.png";
import coverEvangelizerAndMissionary from "../../../assets/cover-evangelizer-and-missionary.png";
import coverBuildYourFaith from "../../../assets/cover-build-your-faith.png";
import coverMigrant from "../../../assets/cover-migrant.png";

import CardProduct from "../../../components/ui/CardProduct/CardProduct"
import InternalBookOfferContainer from "../../../components/InternalBookOfferContainer/InternalBookOfferContainer";
import ExternalBookOfferContainer from "../../../components/ExternalBookOfferContainer/ExternalBookOfferContainer";

import WhiteSectionOverlappedBySimpleCards from "../../../components/WhiteSectionOverlappedBySimpleCards/WhiteSectionOverlappedBySimpleCards"
import BlueSectionOverlappedBySimpleCards from "../../../components/BlueSectionOverlappedBySimpleCards/BlueSectionOverlappedBySimpleCards";
import PreviewCardResourcesStore from "../../../components/PreviewCardResourcesStore/PreviewCardResourcesStore"
import CoursesAndTrainingSection from "../../../components/CoursesAndTrainingSection/CoursesAndTrainingSection"

const HEADER_CONFIG = {
    type: "solid", // solid, image,
    resource: "var(--bs-secondary)", // bg-secondary || cover-home
    icon: "evangelizer-and-missionary-white",
    title: "Lleva la luz de Cristo a cada rincón del mundo. \nEvangeliza con propósito y pasión",
    showBorder: true,
    description: "",
    button: {
        show: true,
        text: "Descarga recursos para discipular",
        type: "btn",
        external: false,
        path: "/library"
    }
}

const evangelizationCampaigns = [
    {
        title: "Evangelización en Parques Locales",
        description: "Nos reunimos en los parques de la ciudad para predicar el Evangelio de manera creativa y cercana. ¡Tu participación es esencial para llevar luz a los rincones más necesitados!",
        button: {
            show: true,
            text: "Unirme",
            path: "https://jsonviewer.stack.hu"
        }
    },
    {
        title: "Campaña de Misión en Comunidades Rurales",
        description: "Acompáñanos en esta misión especial para llevar la esperanza y el amor de Cristo a comunidades rurales. ¡Haz parte de un cambio real!",
        button: {
            show: true,
            text: "Unirme",
            path: "https://jsonviewer.stack.hu"
        }
    },
    {
        title: "Día de Evangelización Virtual",
        description: "Un día completo de evangelización en línea, con actividades interactivas y recursos digitales para compartir la fe en todo el mundo.",
        button: {
            show: true,
            text: "Unirme",
            path: "https://jsonviewer.stack.hu"
        }
    }
]

function EvangelizerAndMissionary() {
    const { config, courses, webinars, forums, resources } = useStore();

    const preProcessSpyNavigations = () => {
        return [
            { id: 'section1', title: 'Materiales de evangelización' },
            { id: 'section2', title: 'Cursos ministeriales' },
            { id: 'section3', title: 'Campañas de evangelización' }
        ]
    }

    const preProcessWhiteSection = () => {
        return {
            mainContext: {
                title: "Estos cursos están diseñados para guiarte en los primeros pasos de tu camino espiritual.",
                description: "Aprende lo esencial sobre la fe cristiana, la oración, y la interpretación de la Biblia para profundizar tu relación con Dios.",
                textButton: "¡Comienza tu aprendizaje hoy!",
                link: "/training"
            },
            simpleCards: courses?.data?.map(item => {
                return {
                    title: item?.title,
                    tag: item?.tag,
                    img: item?.image,
                    description: item?.description,
                    textButton: "Inscribirme",
                    link: item?.link
                }
            })
        }
    }

    return (
        <MainLayout>

            <HeaderCover data={HEADER_CONFIG} />

            <div className="bg-secondary" style={{ height: 200 }}></div>

            <div className="container mb-5">
                <img className="w-100" src={coverEvangelizerAndMissionary} style={{ marginTop: -250 }} />
            </div>

            {/* Spy Navigation */}
            <SpyNavigation navigations={preProcessSpyNavigations()} />

            <section id="section1" className="container-fluid bg-info mt-5">
                <PreviewCardResourcesStore data={{
                    title: "Recursos para Tu Misión",
                    showBorder: true,
                    description: "Aquí encontrarás materiales de evangelización que te ayudarán a compartir el mensaje de Cristo de manera clara y efectiva. Desde recursos gratuitos hasta elementos de nuestra tienda, todo está diseñado para equiparte en tu labor misionera.",
                    sections: []
                }} />
                <InternalBookOfferContainer resources={resources?.data?.filter(item => item.cost == 0)} />
                <ExternalBookOfferContainer resources={resources?.data?.filter(item => item.cost > 0)} />
            </section>

            <section id="section2" className="container">
                <WhiteSectionOverlappedBySimpleCards mainContext={preProcessWhiteSection()?.mainContext} simpleCards={preProcessWhiteSection()?.simpleCards} />
            </section>

            <section id="section3" className="container-fluid mb-5">
                <div className="container">
                    <CardContentDescription cards={[
                        {
                            type: "success",
                            title: "Únete a la Red de Evangelizadores",
                            showBorder: true,
                            rightContent: true,
                            rightImage: "evangelizer-and-missionary-footer-resource",
                            subtitle: "",
                            description: "Sé parte de una comunidad de creyentes comprometidos con la misión de expandir el Evangelio. A través de nuestra Red de Evangelizadores, podrás compartir recursos, experiencias y apoyarte en otros que, como tú, tienen el llamado de llevar la luz de Cristo a todas las naciones. ¡Juntos somos más fuertes en la misión!",
                            button: {
                                show: true,
                                text: "Unirme a la Red de Evangelizadores",
                                type: "btn",
                                external: false,
                                path: "/community"
                            }
                        }
                    ]} />
                </div>
                <div className="container-fluid bg-primary mt-5 pb-5">
                    <PreviewCardResourcesStore data={{
                        title: "¡Participa en nuestras Campañas de Evangelización!",
                        showBorder: true,
                        description: "Únete a nuestras próximas campañas para llevar el mensaje de Cristo a más personas. Aquí encontrarás toda la información para involucrarte y hacer la diferencia. ¡La misión es de todos!",
                        sections: []
                    }}
                        forceTextWhite={true}
                    />
                    <div className="container">
                        <div className="row">
                            {
                                evangelizationCampaigns?.map(item => {
                                    return (
                                        <div className="col-12 col-md-4 mt-4">
                                            <div className="rounded border border-white p-4 text-white">
                                                <h5>{item?.title}</h5>
                                                <p className='mt-3'>{item?.description}</p>
                                                {
                                                    item?.button?.show && <>
                                                        {
                                                            utils?.isExternalLink(item?.button?.path) ? (
                                                                <a
                                                                    href={item?.button?.path}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="btn w-100 mt-4 text-white border border-white text"
                                                                >
                                                                    <p>{item?.button?.text}</p>
                                                                </a>
                                                            ) : (
                                                                <Link className="btn w-100 mt-4 text-white border border-white text">
                                                                    {item?.button?.text}
                                                                </Link>
                                                            )
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

        </MainLayout>
    );
}

export default EvangelizerAndMissionary;
