import React, { useEffect, useState } from 'react';
import useStore from '../../../store/Store'

import MainLayout from "../../../layouts/MainLayout/MainLayout";
import ScrollableContainer from "../../../layouts/ScrollableContainer/ScrollableContainer";

import CardContentDescription from "../../../components/ui/CardContentDescription/CardContentDescription"
import SpyNavigation from "../../../components/ui/SpyNavigation/SpyNavigation"
import CardJoinGroup from "../../../components/ui/CardJoinGroup/CardJoinGroup"
import HeaderCover from "../../../components/ui/HeaderCover/HeaderCover"

import coverNewBeginning from "../../../assets/cover-new-beginnning.png";
import coverEvangelizerAndMissionary from "../../../assets/cover-evangelizer-and-missionary.png";
import coverBuildYourFaith from "../../../assets/cover-build-your-faith.png";
import coverMigrant from "../../../assets/cover-migrant.png";

import CardProduct from "../../../components/ui/CardProduct/CardProduct"
import InternalBookOfferContainer from "../../../components/InternalBookOfferContainer/InternalBookOfferContainer";
import ExternalBookOfferContainer from "../../../components/ExternalBookOfferContainer/ExternalBookOfferContainer";

import WhiteSectionOverlappedBySimpleCards from "../../../components/WhiteSectionOverlappedBySimpleCards/WhiteSectionOverlappedBySimpleCards"
import BlueSectionOverlappedBySimpleCards from "../../../components/BlueSectionOverlappedBySimpleCards/BlueSectionOverlappedBySimpleCards";
import PreviewCardResourcesStore from "../../../components/PreviewCardResourcesStore/PreviewCardResourcesStore"
import CoursesAndTrainingSection from "../../../components/CoursesAndTrainingSection/CoursesAndTrainingSection"


function BuildYourFaith() {
    const { config, courses, webinars, forums, resources } = useStore();

    const HEADER_CONFIG = {
        type: "solid", // solid, image,
        resource: "var(--bs-secondary)", // bg-secondary || cover-home
        icon: "build-your-faith-white",
        title: "No solo crezcas en conocimiento, crece en \nsabiduría y compromiso con tu fe",
        showBorder: true,
        description: "",
        button: {
            show: true,
            text: "Profundiza tu conocimiento bíblico",
            type: "btn",
            external: false,
            path: config?.data?.linkBuildYourFaith || "/"
        }
    }

    const preProcessSpyNavigations = () => {
        return [
            { id: 'section1', title: 'Cursos avanzados' },
            { id: 'section2', title: 'Biblioteca' },
            { id: 'section3', title: 'Foros / Webinars' },
            { id: 'section4', title: 'Grupos de oración y servicio' },
            { id: 'section5', title: 'Libros recomendados' }
        ]
    }

    const preProcessWhiteSection = () => {
        return {
            mainContext: {
                title: "Cursos Avanzados: para profundizar tu Fe",
                description: "Explora nuestra oferta de cursos diseñados para profundizar tu conocimiento y experiencia bíblica. Cada curso está enfocado en brindarte un aprendizaje más profundo y práctico para fortalecer tu vida espiritual.",
                textButton: "Ver toda la oferta académica",
                link: "/training"
            },
            simpleCards: courses?.data?.map(item => {
                return {
                    title: item?.title,
                    tag: item?.tag,
                    img: item?.image,
                    description: item?.description,
                    textButton: "Inscribirme",
                    link: item?.link
                }
            })
        }
    }

    const preProcessBlueSection = () => {
        return {
            mainContext: {
                title: "Webinars",
                subtitle: "¡No te pierdas nuestras clases magistrales gratuitas!",
                description: "Participa en webinars interactivos sobre temas que te ayudarán a vivir tu fe con mayor propósito. Aprende de expertos y comparte con otros que, como tú, buscan crecer espiritualmente.",
                textButton: "",
                link: ""
            },
            simpleCards: webinars?.data?.map(item => {
                return {
                    title: item?.title,
                    date: item?.date,
                    description: item?.description,
                    textButton: "Quiero saber más",
                    link: item?.link
                }
            })
        }
    }

    const preProcessForum = (forum) => {
        return {
            title: "Oración por Paz y Fortaleza",
            createdBy: "12/03/2025",
            status: "Activo",
            description: "Este foro está dedicado a aquellos que buscan paz y fortaleza en momentos difíciles. Comparte tus oraciones, experiencias y súplicas para que juntos podamos elevar nuestras voces al Señor por el consuelo y la paz en tiempos de tribulación.",
            btnJoin: {
                show: true,
                text: "Unírme al foro",
                path: forum?.link
            },
            comments: [],
            members: [],
            ...forum
        }
    }

    return (
        <MainLayout>

            <HeaderCover data={HEADER_CONFIG} />

            <div className="bg-secondary" style={{ height: 200 }}></div>

            <div className="container mb-5">
                <img className="w-100" src={coverBuildYourFaith} style={{ marginTop: -250 }} />
            </div>

            {/* Spy Navigation */}
            <SpyNavigation navigations={preProcessSpyNavigations()} />

            <section id="section1" className="container">
                <WhiteSectionOverlappedBySimpleCards mainContext={preProcessWhiteSection()?.mainContext} simpleCards={preProcessWhiteSection()?.simpleCards} />
            </section>

            <section id="section2" className="container-fluid bg-info">
                <PreviewCardResourcesStore data={{
                    title: "Biblioteca Digital",
                    showBorder: true,
                    description: "Explora una colección única de devocionales, estudios y reflexiones que iluminarán tu camino y te acercarán más a Dios. ¡Comienza a leer hoy!",
                    sections: []
                }} />
                <InternalBookOfferContainer resources={resources?.data?.filter(item => item.cost == 0)} />
            </section>

            <section id="section3" className="container">
                <BlueSectionOverlappedBySimpleCards mainContext={preProcessBlueSection()?.mainContext} simpleCards={preProcessBlueSection()?.simpleCards} />
            </section>

            <section id="section4" className="container">
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center pb-5">
                            <CardContentDescription cards={[
                                {
                                    type: "white",
                                    title: "Peticiones de Oración: Comparte tus Necesidades y Ora por Otros",
                                    showBorder: true,
                                    rightContent: false,
                                    description: "",
                                    button: {
                                        show: false,
                                        text: "¡Comienza tu aprendizaje hoy!",
                                        type: "btn",
                                        external: false,
                                        path: "/any-where"
                                    }
                                }
                            ]} />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <CardJoinGroup card={preProcessForum(forums?.data[Math.floor(Math.random() * forums.data?.length)])} />
                        </div>
                    </div>
                </div>
                <CardContentDescription cards={[
                    {
                        type: "success",
                        title: "Únete a un Grupo de Oración y Servicio",
                        showBorder: true,
                        rightContent: true,
                        rightImage: "build-your-faith-footer-resource",
                        subtitle: "",
                        description: "Forma parte de un grupo de oración y servicio y vive la experiencia de la comunión cristiana, orando y sirviendo juntos para avanzar en el reino de Dios.",
                        button: {
                            show: true,
                            text: "¡Unirme al grupo!",
                            type: "btn",
                            external: false,
                            path: "/community"
                        }
                    }
                ]} />
            </section>

            <section id="section6" className="container-fluid bg-info mt-5">
                <PreviewCardResourcesStore data={{
                    title: "Libros para Fortalecer tu Camino Espiritual",
                    showBorder: true,
                    description: "Encuentra los libros que te inspirarán y te ayudarán a crecer en tu vida cristiana. Cada uno de estos recursos está diseñado para edificarte y acercarte más a la Palabra de Dios.",
                    sections: []
                }} />
                <ExternalBookOfferContainer resources={resources?.data?.filter(item => item.cost > 0)} />
            </section>

        </MainLayout>
    );
}

export default BuildYourFaith;
