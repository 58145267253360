import React, { useEffect, useState } from 'react';
import useStore from '../../../store/Store'

import { Link } from 'wouter'

import utils from '../../../utils/utils';

import MainLayout from "../../../layouts/MainLayout/MainLayout";
import ScrollableContainer from "../../../layouts/ScrollableContainer/ScrollableContainer";

import CardContentDescription from "../../../components/ui/CardContentDescription/CardContentDescription"
import SpyNavigation from "../../../components/ui/SpyNavigation/SpyNavigation"
import CardJoinGroup from "../../../components/ui/CardJoinGroup/CardJoinGroup"
import HeaderCover from "../../../components/ui/HeaderCover/HeaderCover"

import coverNewBeginning from "../../../assets/cover-new-beginnning.png";
import coverEvangelizerAndMissionary from "../../../assets/cover-evangelizer-and-missionary.png";
import coverBuildYourFaith from "../../../assets/cover-build-your-faith.png";
import coverMigrant from "../../../assets/cover-migrant.png";

import CardProduct from "../../../components/ui/CardProduct/CardProduct"
import InternalBookOfferContainer from "../../../components/InternalBookOfferContainer/InternalBookOfferContainer";
import ExternalBookOfferContainer from "../../../components/ExternalBookOfferContainer/ExternalBookOfferContainer";

import WhiteSectionOverlappedBySimpleCards from "../../../components/WhiteSectionOverlappedBySimpleCards/WhiteSectionOverlappedBySimpleCards"
import BlueSectionOverlappedBySimpleCards from "../../../components/BlueSectionOverlappedBySimpleCards/BlueSectionOverlappedBySimpleCards";
import PreviewCardResourcesStore from "../../../components/PreviewCardResourcesStore/PreviewCardResourcesStore"
import CoursesAndTrainingSection from "../../../components/CoursesAndTrainingSection/CoursesAndTrainingSection"

function Migrant() {
    const { config, courses, webinars, forums, resources } = useStore();

    const HEADER_CONFIG = {
        type: "solid", // solid, image,
        resource: "var(--bs-secondary)", // bg-secondary || cover-home
        icon: "migrant-white",
        title: "No importa cuán lejos estés, Dios siempre camina contigo. Encuentra fuerza y comunidad aquí",
        showBorder: true,
        description: "",
        button: {
            show: true,
            text: "Encuentra apoyo y comunidad",
            type: "btn",
            external: false,
            path: config?.data?.linkMigrant || "/"
        }
    }

    const rightsForMigrants = config?.data?.rightsForMigrants || [];

    const preProcessSpyNavigations = () => {
        return [
            { id: 'section1', title: 'Historias' },
            { id: 'section2', title: 'Derechos' },
            { id: 'section3', title: 'Comunidad de acogida' },
            { id: 'section4', title: 'Devocionales' },
            { id: 'section5', title: 'Recursos recomendados' }
        ]
    }

    const preProcessForum = (forum) => {
        return {
            title: "Oración por Paz y Fortaleza",
            createdBy: "12/03/2025",
            status: "Activo",
            description: "Este foro está dedicado a aquellos que buscan paz y fortaleza en momentos difíciles. Comparte tus oraciones, experiencias y súplicas para que juntos podamos elevar nuestras voces al Señor por el consuelo y la paz en tiempos de tribulación.",
            btnJoin: {
                show: true,
                text: "Unírme al foro",
                path: forum?.link
            },
            comments: [],
            members: [],
            ...forum
        }
    }

    const preProcessBlueSection = () => {
        return {
            mainContext: {
                title: "Devocionales de Fortaleza",
                subtitle: "Devocionales de Fortaleza para el Viaje",
                description: "En medio de la incertidumbre, encuentra consuelo y fuerza en nuestros devocionales diseñados para migrantes. Estos mensajes de esperanza te acompañarán cada día, dándote ánimo en tu camino.",
                textButton: "",
                link: ""
            },
            simpleCards: [
                {
                    title: "Fuerza para seguir adelante",
                    date: "",
                    description: "Este devocional está diseñado para ayudarte a mantener la fortaleza en medio de las dificultades. A través de reflexiones inspiradoras y versículos bíblicos, encontrarás ánimo para continuar tu camino con esperanza, incluso cuando las circunstancias parecen inciertas.",
                    textButton: "Leer el Devocional",
                    link: "https://jsonviewer.stack.hu/"
                },
                {
                    title: "La esperanza en cada paso",
                    date: "",
                    description: "La esperanza es un faro que ilumina incluso los momentos más oscuros. Este devocional te guiará a encontrar la paz interior y la confianza en Dios a medida que enfrentas los desafíos del viaje migrante. Cada paso que das tiene un propósito.",
                    textButton: "Leer el Devocional",
                    link: "https://jsonviewer.stack.hu/"
                }
            ]
        }
    }

    return (
        <MainLayout>

            <HeaderCover data={HEADER_CONFIG} />

            <div className="bg-secondary" style={{ height: 200 }}></div>

            <div className="container mb-5">
                <img className="w-100" src={coverMigrant} style={{ marginTop: -250 }} />
            </div>

            {/* Spy Navigation */}
            <SpyNavigation navigations={preProcessSpyNavigations()} />

            <section id="section1" className="container">
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center pb-5">
                            <CardContentDescription cards={[
                                {
                                    type: "white",
                                    title: "Historias de Esperanza",
                                    showBorder: true,
                                    rightContent: false,
                                    description: "Encuentra relatos inspiradores de migrantes que han encontrado fuerza, fe y comunidad en su camino. Cada historia es un testimonio de resistencia y esperanza ante las adversidades del viaje.",
                                    button: {
                                        show: true,
                                        text: "¡Explora todos los foros!",
                                        type: "btn",
                                        external: false,
                                        path: "/community"
                                    }
                                }
                            ]} />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <CardJoinGroup card={preProcessForum(forums?.data[Math.floor(Math.random() * forums.data.length)])} />
                        </div>
                    </div>
                </div>
            </section>

            <section id="section2" className="container-fluid bg-secondary mt-5 pb-5">
                <PreviewCardResourcesStore data={{
                    title: "Derechos y Apoyo para Migrantes",
                    showBorder: true,
                    description: "Aquí encontrarás información esencial sobre los derechos que tienes como migrante y los recursos disponibles para tu protección y bienestar. Accede a guías legales, servicios de apoyo y asistencia para superar las dificultades de la migración.",
                    sections: []
                }}
                    forceTextWhite={true}
                />
                <div className="container">
                    <div className="row">
                        {
                            rightsForMigrants?.map(item => {
                                return (
                                    <div className="col-12 col-md-4 mt-4">
                                        <div className="rounded border border-white p-4 text-white">
                                            <h5>{item?.title}</h5>
                                            <p className='mt-3'>{item?.description}</p>
                                            {
                                                item?.button?.show && <>
                                                    {
                                                        utils?.isExternalLink(item?.button?.path) ? (
                                                            <a
                                                                href={item?.button?.path}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="btn w-100 mt-4 text-white border border-white text"
                                                            >
                                                                <p>{item?.button?.text}</p>
                                                            </a>
                                                        ) : (
                                                            <Link className="btn w-100 mt-4 text-white border border-white text">
                                                                {item?.button?.text}
                                                            </Link>
                                                        )
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            <section id="section3" className="container pt-5 mt-5">
                <CardContentDescription cards={[
                    {
                        type: "success",
                        title: "Únete a la Comunidad de Acogida",
                        showBorder: true,
                        rightContent: true,
                        rightImage: "migrant-footer-resource",
                        subtitle: "",
                        description: "Formar parte de nuestra comunidad de acogida es un paso hacia un lugar seguro y lleno de apoyo. Encuentra consuelo, solidaridad y ayuda de otros migrantes y de personas dispuestas a brindarte su mano amiga.",
                        button: {
                            show: true,
                            text: "Únete ahora y comparte tu experiencia.",
                            type: "btn",
                            external: false,
                            path: "/community"
                        }
                    }
                ]} />
            </section>

            <section id="section4" className="container">
                <BlueSectionOverlappedBySimpleCards mainContext={preProcessBlueSection()?.mainContext} simpleCards={preProcessBlueSection()?.simpleCards} />
            </section>

            <section id="section5" className="container-fluid bg-info mt-5">
                <PreviewCardResourcesStore data={{
                    title: "Recursos Recomendados para Migrantes",
                    showBorder: true,
                    description: "Descubre materiales esenciales que te ayudarán en tu viaje espiritual y práctico. Desde Biblias hasta recursos de apoyo, tenemos todo lo que necesitas para fortalecer tu fe y encontrar esperanza.",
                    sections: []
                }} />
                <ExternalBookOfferContainer resources={resources?.data?.filter(item => item.cost > 0)} />
            </section>

        </MainLayout>
    );
}

export default Migrant;
