import React from 'react';

function CardJoinGroup({ card }) {
    return (
        <div className='border p-4' style={{ borderRadius: 20 }}>
            <div className="p-2 w-100 d-flex">
                <p className='mt-auto mb-auto border-end p-2 ps-0 fw-bold text-gray-dark'>Creado el {card?.createdBy}</p>
                <p className='mt-auto mb-auto p-2 fw-bold text-gray-dark'>{card?.status}</p>
                <button className='ms-auto btn btn-secondary'>
                    Unirme al foro
                </button>
            </div>
            <div className="border-bottom border-top p-2">
                <h5>{card?.title}</h5>
                <p>{card?.description}</p>
            </div>
            <div className="p-2 w-100 d-flex">
                <button className='btn'>
                    <div className="w-100 d-flex">
                        <div className='pt-1 me-2'>
                            <ion-icon name="people-outline"></ion-icon>
                        </div>
                        <p className='mt-auto mb-auto'>{card?.members?.length} Miembros</p>
                    </div>
                </button>
                <button className='btn ms-auto'>
                    <div className="w-100 d-flex">
                        <div className='pt-1 me-2'>
                            <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                        </div>
                        <p className='mt-auto mb-auto'>{card?.comments?.length} Comentarios</p>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default CardJoinGroup;