import React, { useEffect, useState } from 'react';
import useStore from '../../store/Store';

import utils from "../../utils/utils";

import MainLayout from "../../layouts/MainLayout/MainLayout";
import CardContentDescription from "../../components/ui/CardContentDescription/CardContentDescription";
import CardProductExternal from "../../components/ui/CardProduct/CardProductExternal"
import CardProduct from "../../components/ui/CardProduct/CardProduct";

import ExternalBookOfferContainer from "../../components/ExternalBookOfferContainer/ExternalBookOfferContainer";
import StoreLibraryWithAdvanceFilters from "../../components/StoreLibraryWithAdvanceFilters/StoreLibraryWithAdvanceFilters"

function Evangelism() {
    const { config, resources } = useStore();

    const HEADER_CONFIG = [
        {
            type: "success",
            title: "La Gran Comisión sigue viva. Lleva el mensaje de Cristo con herramientas efectivas y poderosas",
            showBorder: true,
            rightContent: true,
            rightImage: "hand-resource",
            subtitle: "",
            description: "",
            button: {
                show: true,
                text: "Empieza tu misión hoy",
                type: "btn",
                external: false,
                path: config?.data?.linkEvangelism || "/",
            }
        }
    ]

    function Header() {
        return (
            <div className="container mt-5 mb-5">
                <CardContentDescription cards={HEADER_CONFIG} />
            </div>
        );
    }

    return (
        <MainLayout>
            <Header />
            <StoreLibraryWithAdvanceFilters
                resources={resources?.data?.filter(item => item?.cost == 0)}
                contentTypes={config?.data?.isTypeEvangelizationResource?.map(item => ({ id: item, label: item }))}
                categories={config?.data?.isTagEvangelizationResource?.map(item => ({ id: item, label: item }))}
            />
            <ExternalBookOfferContainer resources={resources?.data?.filter(item => item.cost > 0)} />
        </MainLayout>
    );
}

export default Evangelism;
