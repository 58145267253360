import ScrollableContainer from "../../layouts/ScrollableContainer/ScrollableContainer";

import CardContentDescription from "../../components/ui/CardContentDescription/CardContentDescription"
import CardProduct from "../../components/ui/CardProduct/CardProduct"

// mainContext = {title, subtitle, description, textButton, link}
// simpleCards = {title, date, description, textButton, link}

function BlueSectionOverlappedBySimpleCards({ mainContext, simpleCards }) {
    const preProcessMain = () => {
        return [
            {
                type: "secondary",
                title: mainContext?.title,
                showBorder: true,
                rightContent: false,
                subtitle: mainContext?.subtitle,
                description: mainContext?.description,
                button: {
                    show: mainContext?.textButton?.length > 0,
                    text: mainContext?.textButton,
                    type: "link",
                    external: false,
                    path: mainContext?.link
                }
            }
        ]
    }
    const preProcessCards = () => {
        return simpleCards?.map((card) => {
            return {
                bg: "border bg-light2",
                img: "",
                title: card?.title,
                tag: "",
                date: card?.date,
                description: card?.description,
                buttonOne: {
                    show: true,
                    text: card?.textButton,
                    path: card?.link,
                    type: "w-100 mt-1 mb-1 btn btn-warning text-white"
                },
                buttonTwo: {
                    show: false,
                    text: "Leer en línea",
                    type: "w-100 mt-1 mb-1 btn btn-secondary"
                }
            }
        })
    }
    return <div className="container mt-5 mb-5">
        <div className="row">
            <div className="col-12 col-md-6 mt-3 mb-3 d-flex align-items-center pb-5">
                <CardContentDescription cards={preProcessMain()} />
            </div>
            <ScrollableContainer className="col-12 col-md-6 mt-3 mb-3">
                <>
                    {
                        preProcessCards()?.map((card) => {
                            return (
                                <div className="ms-2 me-2">
                                    <CardProduct card={card} />
                                </div>
                            )
                        })
                    }
                </>
            </ScrollableContainer>
        </div>
    </div>
}

export default BlueSectionOverlappedBySimpleCards;